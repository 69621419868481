import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import {
  AppointmentMedication,
  CreateAppointmentMedicationInput,
  DeleteAppointmentMedicationInput,
  ModelAppointmentMedicationFilterInput,
  ModelSortDirection,
} from '../../API'
import { createAppointmentMedication, deleteAppointmentMedication } from '../../graphql/mutations'
import { findAppointmentMedicationByAppointmentId, listAppointmentMedications } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class AppointmentMedicationService {
  private static service: AppointmentMedicationService | undefined

  public async findByAppointmentId(appointmentID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findAppointmentMedicationByAppointmentId,
        variables: { appointmentID, sortDirection: ModelSortDirection.ASC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findAppointmentMedicationByAppointmentId?.items,
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn('error fetching appointment medications: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async listAll(filter?: ModelAppointmentMedicationFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listAppointmentMedications,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listAppointmentMedications?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching appointment notes: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async add(appointmentMedicationCommand: CreateAppointmentMedicationInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: createAppointmentMedication,
        variables: { input: appointmentMedicationCommand },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createAppointmentMedication, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on add : ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(appointmentMedication: AppointmentMedication): Promise<TbnResponse> {
    try {
      const input: DeleteAppointmentMedicationInput = {
        id: appointmentMedication.id,
      }
      const res: any = await API.graphql({
        query: deleteAppointmentMedication,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteAppointmentMedication, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on remove : ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): AppointmentMedicationService {
    if (!this.service) {
      this.service = new AppointmentMedicationService()
    }
    return this.service
  }
}

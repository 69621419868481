import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonCol, IonGrid } from '@ionic/react'
import { IonIcon, IonItem, IonLabel, IonList, IonReorder, IonReorderGroup } from '@ionic/react'
import { IonRow, IonText, ItemReorderEventDetail, useIonAlert, useIonToast } from '@ionic/react'
import { archive, ribbon } from 'ionicons/icons'
import { Doctor, MedicalAuthorisedPrescriber, TgaStatus } from '../../../API'
import { MedicalAuthorisedPrescriberService } from '../../../components/services/MedicalAuthorisedPrescriberService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { convertISOtoDateFormat1, isBeforeToday } from '../../../components/util/Date'
import { log } from '../../../components/util/Log'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { failure } from '../../../components/util/Toast'
import MapNumberModalForm from './MapNumberModalForm'

interface DoctorMapNumberListProps {
  doctor: Doctor
  reload?: boolean
}
const DoctorMapNumberList: FC<DoctorMapNumberListProps> = ({ doctor, reload }) => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [archiving, setArchiving] = useState(false)
  const [presentAlert] = useIonAlert()
  const [presentToast] = useIonToast()
  const [showEditModal, setShowEditModal] = useState(false)
  const [activeMap, setActiveMap] = useState<MedicalAuthorisedPrescriber>()

  const fetchItems = async () => {
    setLoading(true)
    const res: TbnResponse = await MedicalAuthorisedPrescriberService.Instance.listAll({ doctorID: { eq: doctor?.id } })
    if (!!res.data) {
      setItems(res.data)
    }
    setLoading(false)
  }

  const archiveItem = async (cmd: MedicalAuthorisedPrescriber) => {
    setArchiving(true)
    const res: TbnResponse = await MedicalAuthorisedPrescriberService.Instance.archive(cmd)
    if (!!res?.data) {
      await fetchItems()
    } else if (!!res?.errorMessage) {
      failure(res.errorMessage, presentToast)
    }
    setArchiving(false)
  }

  const updateListOrder = async () => {
    const persistOperations = []
    let idxOrder = 1
    for (let itm of items) {
      const cmd = itm as MedicalAuthorisedPrescriber
      cmd.idxOrder = JSON.parse(JSON.stringify(idxOrder))
      persistOperations.push(MedicalAuthorisedPrescriberService.Instance.updateIndex(cmd))
      idxOrder++
    }
    await Promise.allSettled(persistOperations)
  }

  const handleReorder = async (evt: CustomEvent<ItemReorderEventDetail>) => {
    let movedItem = items.splice(evt.detail.from, 1)[0]
    log('Dragged from index', evt.detail.from, 'to', evt.detail.to, movedItem)
    items.splice(evt.detail.to, 0, movedItem)
    setItems(items)
    evt.detail.complete()
    await updateListOrder()
  }

  useEffect(() => {
    if (!!doctor?.id) fetchItems()
  }, [doctor?.id, reload]) // eslint-disable-line

  return (
    <IonGrid>
      {loading && (
        <IonRow>
          <IonCol>
            <TbnSpinner />
          </IonCol>
        </IonRow>
      )}
      <IonList>
        <IonReorderGroup onIonItemReorder={handleReorder} disabled={false}>
          {!loading &&
            items.map((itm: MedicalAuthorisedPrescriber) => (
              <IonItem key={itm.id} button={true}>
                <IonIcon
                  slot='start'
                  icon={ribbon}
                  color={itm.status === TgaStatus.TGA_APPROVED ? 'dark' : 'warning'}
                />
                <IonLabel
                  className='ion-text-wrap'
                  onClick={() => {
                    setActiveMap(itm)
                    setShowEditModal(true)
                  }}
                >
                  {itm.mapNumber}
                </IonLabel>
                <IonButtons slot='end'>
                  <IonText color={isBeforeToday(itm?.expiryDate || '1960-00-00') ? 'danger' : ''} slot='end'>
                    {convertISOtoDateFormat1(itm.expiryDate)}
                  </IonText>
                  <IonButton
                    fill='clear'
                    color='danger'
                    disabled={archiving}
                    title='Archive'
                    onClick={() => {
                      presentAlert({
                        header: `Are you sure?`,
                        subHeader: 'After archive, MAP number will not be usable any where.',
                        message: `${itm.mapNumber}`,
                        buttons: [
                          {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {},
                          },
                          {
                            text: 'Archive',
                            role: 'destructive',
                            cssClass: 'alert-button-delete',
                            handler: () => {
                              archiveItem(itm)
                            },
                          },
                        ],
                        onDidDismiss: (e: CustomEvent) => {},
                      })
                    }}
                  >
                    <IonIcon icon={archive} slot='icon-only' />
                  </IonButton>
                  <IonReorder></IonReorder>
                </IonButtons>
              </IonItem>
            ))}
        </IonReorderGroup>
      </IonList>
      {!!activeMap && (
        <div>
          <MapNumberModalForm
            map={activeMap}
            isOpen={showEditModal}
            onDidDismiss={(e: any) => {
              setShowEditModal(false)
              if (!!e?.detail?.data) {
                fetchItems()
              }
              setTimeout(() => {
                setActiveMap(undefined)
              }, 0)
            }}
          />
        </div>
      )}
    </IonGrid>
  )
}

export default DoctorMapNumberList

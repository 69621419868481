import { FC, useRef, useState } from 'react'
import { FaLeaf, FaCapsules, FaFlask, FaSprayCan } from 'react-icons/fa'
import { IonButton, IonButtons, IonContent, IonFooter, IonInput } from '@ionic/react'
import { IonItem, IonLabel, IonModal, IonSegment, IonSegmentButton } from '@ionic/react'
import { IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { CannabisProduct, ProductCategory, ProductPresentation } from '../../../API'
import productPresentationReverse from '../../../components/models/ProductPresentationReverse'
import { CannabisProductService } from '../../../components/services/CannabisProductService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure } from '../../../components/util/Toast'
import './CannabisProductModalForm.scss'

interface OwnProps {
  product?: CannabisProduct
  isOpen?: boolean
  onDidDismiss: (event: any) => void
}
interface CannabisProductModalFormProps extends OwnProps {}
const CannabisProductModalForm: FC<CannabisProductModalFormProps> = ({ isOpen, onDidDismiss, product }) => {
  const [model, setModel] = useState<CannabisProduct>(
    !!product
      ? product
      : ({
          idxOrder: 0,
          presentation: ProductPresentation.CAPSULE,
          presentationText: productPresentationReverse(ProductPresentation.CAPSULE),
          category: undefined,
        } as CannabisProduct),
  )
  const [saving, setSaving] = useState(false)
  const [presentToast] = useIonToast()
  const modalRef = useRef<any>()

  const store = async () => {
    setSaving(true)
    try {
      const res: TbnResponse = await CannabisProductService.Instance.store(model)
      if (res.data) modalRef?.current?.dismiss(res.data)
      else if (res.errorMessage) failure(res.errorMessage, presentToast)
    } catch (err) {
      failure(JSON.stringify(err), presentToast)
    }
    setSaving(false)
  }

  const isInvalid = () => {
    return !model.presentation
  }

  return (
    <IonModal id='cannabis-product-modal-form' isOpen={isOpen} onDidDismiss={onDidDismiss} ref={modalRef}>
      <IonContent className='ion-padding'>
        <IonItem>
          <IonLabel className='required'>Product Name</IonLabel>
          <IonInput value={model.name} onIonChange={(e: any) => setModel({ ...model, name: e.detail.value })} />
        </IonItem>
        <IonItem>
          <IonSegment value={model.category} onIonChange={(e: any) => setModel({ ...model, category: e.detail.value })}>
            <IonSegmentButton value={ProductCategory.CATEGORY_1}>Cat 1</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_2}>Cat 2</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_3}>Cat 3</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_4}>Cat 4</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_5}>Cat 5</IonSegmentButton>
            <IonSegmentButton value={undefined}>-</IonSegmentButton>
          </IonSegment>
        </IonItem>
        <IonItem>
          <IonSegment
            value={model.presentation}
            onIonChange={(e: any) => {
              setModel({
                ...model,
                presentation: e.detail.value,
                presentationText: productPresentationReverse(e.detail.value),
              })
            }}
          >
            <IonSegmentButton value={ProductPresentation.CAPSULE} title='Capsule'>
              <FaCapsules />
            </IonSegmentButton>
            <IonSegmentButton value={ProductPresentation.DRIED_HERB} title='Dried Herb'>
              <FaLeaf />
            </IonSegmentButton>
            <IonSegmentButton value={ProductPresentation.ORAL_LIQUID} title='Oral Liquid'>
              <FaFlask />
            </IonSegmentButton>
            <IonSegmentButton value={ProductPresentation.SPRAY_SOLUTION} title='Spray Solution'>
              <FaSprayCan />
            </IonSegmentButton>
          </IonSegment>
        </IonItem>
        <IonItem>
          <IonLabel>Dosage form</IonLabel>
          <IonInput
            placeholder='Dosage form'
            value={model.presentationText}
            onIonChange={(e: any) => setModel({ ...model, presentationText: e.detail.value })}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Active ingredient(s)</IonLabel>
          <IonInput
            value={model.ingredients}
            onIonChange={(e: any) => setModel({ ...model, ingredients: e.detail.value })}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Strength</IonLabel>
          <IonInput value={model.strength} onIonChange={(e: any) => setModel({ ...model, strength: e.detail.value })} />
        </IonItem>
        <IonItem>
          <IonLabel>Volume</IonLabel>
          <IonInput value={model.volume} onIonChange={(e: any) => setModel({ ...model, volume: e.detail.value })} />
        </IonItem>
        <IonItem>
          <IonLabel>Qty per dosage unit</IonLabel>
          <IonInput
            value={model.qtyPerDosageUnit}
            onIonChange={(e: any) => setModel({ ...model, qtyPerDosageUnit: e.detail.value })}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Name of Sponsor</IonLabel>
          <IonInput value={model.sponsor} onIonChange={(e: any) => setModel({ ...model, sponsor: e.detail.value })} />
        </IonItem>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => onDidDismiss(null)}>Cancel</IonButton>
            <IonButton color='primary' disabled={saving || isInvalid()} onClick={store}>
              {saving && <IonSpinner slot='start' />}
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default CannabisProductModalForm

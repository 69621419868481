import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { PatientLink, UpdatePatientLinkInput } from '../../API'
import { ModelPatientLinkFilterInput, CreatePatientLinkInput } from '../../API'
import { getPatientLink } from '../../graphql-custom/search/queries'
import { listPatientLinks } from '../../graphql-custom/search/queries'
import { createPatientLink, updatePatientLink } from '../../graphql/mutations'
import { warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class PatientLinkService {
  private static service: PatientLinkService | undefined

  public async find(doctorID: string, patientID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: getPatientLink,
        variables: { doctorID, patientID },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.getPatientLink,
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn('error finding: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async list(filter: ModelPatientLinkFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listPatientLinks,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listPatientLinks?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching PatientLinks: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(patientLink: PatientLink | CreatePatientLinkInput): Promise<TbnResponse> {
    try {
      const existed = await this.find(patientLink.doctorID, patientLink.patientID)
      if (!!existed?.data) {
        const input: UpdatePatientLinkInput = {
          doctorID: patientLink.doctorID,
          patientID: patientLink.patientID,
          clinicID: patientLink.clinicID,
        }
        const res: any = await API.graphql({
          query: updatePatientLink,
          variables: { input },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        return { data: res?.updatePatientLink, errorMessage: res?.errors?.[0]?.message }
      } else {
        const res: any = await API.graphql({
          query: createPatientLink,
          variables: { input: patientLink },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        return { data: res?.data?.createPatientLink, errorMessage: res?.errors?.[0]?.message }
      }
    } catch (err) {
      warn('error on persist: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): PatientLinkService {
    if (!this.service) {
      this.service = new PatientLinkService()
    }
    return this.service
  }
}

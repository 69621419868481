import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Doctor } from '../../API'
import { CurrentDoctorService } from '../../components/services/CurrentDoctorService'
import { isDoctor } from '../../components/util/Auth'
import TbnSpinner from '../../components/util/TbnSpinner'
import AppointmentsPage from './AppointmentsPage'
import DoctorContainer from './DoctorContainer'

interface OwnProps extends RouteComponentProps {}
interface StateProps {}
interface DispatchProps {}
interface AppointmentsProps extends OwnProps, StateProps, DispatchProps {}
const Appointments: FC<AppointmentsProps> = ({ match, history, location, staticContext }) => {
  const [loading, setLoading] = useState(false)
  const { user } = useAuthenticator((context) => [context.user])
  const [doctorID, setDoctorID] = useState<string>()

  const loadDoctorLoop = async (): Promise<Doctor> => {
    try {
      return await CurrentDoctorService.Instance?.currentDoctor()
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return loadDoctorLoop()
    }
  }

  const loadDoctor = async () => {
    setLoading(true)
    const doctor: Doctor = await loadDoctorLoop()
    setDoctorID(doctor?.id)
    setLoading(false)
  }

  useEffect(() => {
    loadDoctor()
  }, []) // eslint-disable-line

  return (
    <>
      {!loading && isDoctor(user) && (
        <AppointmentsPage
          myDoctorID={doctorID}
          match={match}
          history={history}
          location={location}
          staticContext={staticContext}
        />
      )}
      {!loading && !isDoctor(user) && <IonText color='danger'>You don't have a doctor role</IonText>}
      {loading && (
        <DoctorContainer id='appointments-loading' title='Appointments'>
          <TbnSpinner />
        </DoctorContainer>
      )}
    </>
  )
}

export default Appointments

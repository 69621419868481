export const createTypeFormEvent = /* GraphQL */ `
  mutation CreateTypeFormEvent($input: CreateTypeFormEventInput!, $condition: ModelTypeFormEventConditionInput) {
    createTypeFormEvent(input: $input, condition: $condition) {
      id
      definitionID
      definition {
        id
        appointmentType
        appointmentSubject
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
        }
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
      }
      definitionTitle
      definitionSlug
      definitionType
      definitionSubject
      who
      email
      phone
      firstName
      bookingUrl
      oldBookingUrl
      startTime
      endTime
    }
  }
`

export const findDoctorByDrId = /* GraphQL */ `
  query FindDoctorByDrId(
    $drId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findDoctorByDrId(
      drId: $drId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        drId
        drName
        avatar
      }
      nextToken
    }
  }
`

export const findAppointmentDefinitionByDrPublicId = /* GraphQL */ `
  query FindAppointmentDefinitionByDrPublicId(
    $drPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByDrPublicId(
      drPublicId: $drPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
      }
      nextToken
    }
  }
`

export const findAppointmentDefinitionByClinicPublicId = /* GraphQL */ `
  query FindAppointmentDefinitionByClinicPublicId(
    $clinicPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByClinicPublicId(
      clinicPublicId: $clinicPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
      }
      nextToken
    }
  }
`

export const getAppointmentDefinition = /* GraphQL */ `
  query GetAppointmentDefinition($id: ID!) {
    getAppointmentDefinition(id: $id) {
      id
      appointmentType
      appointmentSubject
      calendlyAppointmentID
      calendlyAppointment {
        id
        slug
        name
        uri
        scheduling_url
      }
      drPublicId
      drName
      drAvatar
      clinicPublicId
      clinicName
      clinicFormerlyKnownAs
      clinicStatus
      clinicLogoImage
      active
    }
  }
`

import { FC, useEffect, useState } from 'react'
import { IonButton, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPopover, IonRow, IonText } from '@ionic/react'
import { AppointmentSubject, TypeFormEvent } from '../../API'
import appointmentSubjectLabel from '../../components/models/AppointmentSubjectLabel'
import { utcToLocale } from '../../components/util/Date'
import groupByProp from '../../components/util/GroupByProp'
import TbnSpinner from '../../components/util/TbnSpinner'
import { connect } from '../../data'

interface OwnProps {
  appts: TypeFormEvent[]
}
interface StateProps {
  net: boolean
}
interface DispatchProps {}
interface AppointmentStatProps extends OwnProps, StateProps, DispatchProps {}
const AppointmentStat: FC<AppointmentStatProps> = ({ appts, net }) => {
  const [stat, setStat] = useState<any>([])
  const [render, setRender] = useState(false)
  let doctors = groupByProp(appts, (itm: TypeFormEvent) => itm.doctor?.drId)

  useEffect(() => {
    Array.from(doctors).map((group: any, idx: number) => {
      stat[idx] = Array.from(groupByProp(group[1], (itm: TypeFormEvent) => itm?.definitionSubject))
      return stat
    })
    setStat(stat)
    setRender(true)
  }, [appts]) // eslint-disable-line

  useEffect(() => {
    if (net) setRender(false)
  }, [net]) // eslint-disable-line

  return (
    <IonGrid>
      <IonRow className='tr-header'>
        <IonCol></IonCol>
        <IonCol>{appointmentSubjectLabel(AppointmentSubject.INITIAL_CONSULTATION)}</IonCol>
        <IonCol>{appointmentSubjectLabel(AppointmentSubject.FIRST_FOLLOW_UP)}</IonCol>
        <IonCol>{appointmentSubjectLabel(AppointmentSubject.LONG_FOLLOW_UP)}</IonCol>
        <IonCol>{appointmentSubjectLabel(AppointmentSubject.USUAL_FOLLOW_UP)}</IonCol>
      </IonRow>
      {appts.length === 0 && !net && <IonText>No record found for given dates</IonText>}
      {appts.length === 0 && net && <TbnSpinner />}
      {render &&
        Array.from(doctors).map((group: any, idx: number) => (
          <IonRow key={group[0]}>
            <IonCol>{group?.[1]?.[0]?.doctor?.drName}</IonCol>
            <IonCol>
              <IonButton
                fill='clear'
                id={stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.INITIAL_CONSULTATION)?.[1]?.[0]?.id}
              >
                {stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.INITIAL_CONSULTATION)?.[1]?.length}
              </IonButton>
              <IonPopover
                trigger={
                  stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.INITIAL_CONSULTATION)?.[1]?.[0]?.id
                }
                triggerAction='click'
                size='auto'
              >
                <IonContent class='ion-padding'>
                  {stat[idx]
                    ?.find((arr: any[]) => arr?.[0] === AppointmentSubject.INITIAL_CONSULTATION)?.[1]
                    ?.map((itm: TypeFormEvent) => (
                      <IonItem key={itm.id}>
                        <IonLabel className='ion-text-wrap'>{utcToLocale(itm?.startTime)}</IonLabel>
                      </IonItem>
                    ))}
                </IonContent>
              </IonPopover>
            </IonCol>
            <IonCol>
              <IonButton
                fill='clear'
                id={stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.FIRST_FOLLOW_UP)?.[1]?.[0]?.id}
              >
                {stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.FIRST_FOLLOW_UP)?.[1]?.length}
              </IonButton>
              <IonPopover
                trigger={stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.FIRST_FOLLOW_UP)?.[1]?.[0]?.id}
                triggerAction='click'
                size='auto'
              >
                <IonContent class='ion-padding'>
                  {stat[idx]
                    ?.find((arr: any[]) => arr?.[0] === AppointmentSubject.FIRST_FOLLOW_UP)?.[1]
                    ?.map((itm: TypeFormEvent) => (
                      <IonItem key={itm.id}>
                        <IonLabel className='ion-text-wrap'>{utcToLocale(itm?.startTime)}</IonLabel>
                      </IonItem>
                    ))}
                </IonContent>
              </IonPopover>
            </IonCol>
            <IonCol>
              <IonButton
                fill='clear'
                id={stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.LONG_FOLLOW_UP)?.[1]?.[0]?.id}
              >
                {stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.LONG_FOLLOW_UP)?.[1]?.length}
              </IonButton>
              <IonPopover
                trigger={stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.LONG_FOLLOW_UP)?.[1]?.[0]?.id}
                triggerAction='click'
                size='auto'
              >
                <IonContent class='ion-padding'>
                  {stat[idx]
                    ?.find((arr: any[]) => arr?.[0] === AppointmentSubject.LONG_FOLLOW_UP)?.[1]
                    ?.map((itm: TypeFormEvent) => (
                      <IonItem key={itm.id}>
                        <IonLabel className='ion-text-wrap'>{utcToLocale(itm?.startTime)}</IonLabel>
                      </IonItem>
                    ))}
                </IonContent>
              </IonPopover>
            </IonCol>
            <IonCol>
              <IonButton
                fill='clear'
                id={stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.USUAL_FOLLOW_UP)?.[1]?.[0]?.id}
              >
                {stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.USUAL_FOLLOW_UP)?.[1]?.length}
              </IonButton>
              <IonPopover
                trigger={stat[idx]?.find((arr: any[]) => arr?.[0] === AppointmentSubject.USUAL_FOLLOW_UP)?.[1]?.[0]?.id}
                triggerAction='click'
                size='auto'
              >
                <IonContent class='ion-padding'>
                  {stat[idx]
                    ?.find((arr: any[]) => arr?.[0] === AppointmentSubject.USUAL_FOLLOW_UP)?.[1]
                    ?.map((itm: TypeFormEvent) => (
                      <IonItem key={itm.id}>
                        <IonLabel className='ion-text-wrap'>{utcToLocale(itm?.startTime)}</IonLabel>
                      </IonItem>
                    ))}
                </IonContent>
              </IonPopover>
            </IonCol>
          </IonRow>
        ))}
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  component: AppointmentStat,
})

import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useLocation, withRouter } from 'react-router'
import { IonContent, IonFooter, IonIcon, IonItem } from '@ionic/react'
import { IonItemDivider, IonLabel, IonToggle, IonToolbar } from '@ionic/react'
import { IonList, IonMenu, IonMenuToggle, useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { at, business, calendar, calendarClear, medkit } from 'ionicons/icons'
import { calendarNumber, link, logIn, logOut, people, speedometer } from 'ionicons/icons'
import { moonOutline, pencil, peopleCircle, person } from 'ionicons/icons'
import { Clinic } from '../../API'
import ClinicStatusComp from '../../components/models/ClinicStatus'
import { CurrentAccountantService } from '../../components/services/CurrentAccountantService'
import { CurrentAdminService } from '../../components/services/CurrentAdminService'
import { CurrentReceptionService } from '../../components/services/CurrentReceptionService'
import { isDoctor, isAdmin, isTechAdmin, isReception, isAccountant, isClinicAdmin } from '../../components/util/Auth'
import { setDarkMode, connect } from '../../data'
import './Menu.scss'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  darkMode: boolean
  menuEnabled: boolean
}
interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends OwnProps, StateProps, DispatchProps {}

const Menu: FC<MenuProps> = ({ darkMode, setDarkMode, menuEnabled, history }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [present] = useIonToast()
  const location = useLocation()
  const [adminClinic, setAdminClinic] = useState<Clinic | null>()
  const [receptionClinic, setReceptionClinic] = useState<Clinic | null>()
  const [accountantClinic, setAccountantClinic] = useState<Clinic | null>()

  const toastInfo = (message: string) => {
    present({ message, color: 'tertiary', duration: 3000 })
  }

  const resolveReceptionClinic = async (): Promise<Clinic> => {
    try {
      const reception = await CurrentReceptionService.Instance.currentClinic()
      return reception
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveReceptionClinic()
    }
  }

  const resolveAccountantClinic = async (): Promise<Clinic> => {
    try {
      const accountant = await CurrentAccountantService.Instance.currentClinic()
      return accountant
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveAccountantClinic()
    }
  }

  const resolveAdminClinic = async (): Promise<Clinic> => {
    try {
      const admin = await CurrentAdminService.Instance.currentClinic()
      return admin
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveAccountantClinic()
    }
  }

  const resolveClinic = async () => {
    if (!!user && isReception(user) && !receptionClinic) {
      const clinic: Clinic = await resolveReceptionClinic()
      if (!!clinic) setReceptionClinic(clinic)
    }
    if (!!user && isAccountant(user) && !accountantClinic) {
      const clinic: Clinic = await resolveAccountantClinic()
      if (!!clinic) setAccountantClinic(clinic)
    }
    if (!!user && isClinicAdmin(user) && !adminClinic) {
      const clinic: Clinic = await resolveAdminClinic()
      if (!!clinic) setAdminClinic(clinic)
    }
  }

  useEffect(() => {
    if (!!user && (!receptionClinic || !accountantClinic || !adminClinic)) resolveClinic()
  }, [user]) // eslint-disable-line

  return (
    <IonMenu contentId='main' type='overlay' disabled={!menuEnabled}>
      <IonContent forceOverscroll={false} className='ion-no-padding'>
        <IonList lines='none' style={{ paddingTop: '0px' }}>
          <IonMenuToggle autoHide={false}>
            <div id='logo-container' onClick={() => history.push('/')}>
              <img src={`../../assets/img/logo-text-colorful${darkMode ? '-dark' : ''}.svg`} alt='' width='130px' />
            </div>
            <IonItem
              type='button'
              routerLink='/profile'
              color={location?.pathname?.startsWith('/profile') ? (darkMode ? 'dark' : 'third') : ''}
            >
              <IonIcon icon={authStatus === 'authenticated' ? person : logIn} slot='start' />
              {authStatus === 'authenticated' ? user?.attributes?.given_name || 'Profile' : 'Sign in'}
            </IonItem>
            {isAdmin(user) && (
              <>
                <IonItemDivider>Admin panel</IonItemDivider>
                <IonItem
                  type='button'
                  routerLink='/admin/users'
                  color={location?.pathname?.startsWith('/admin/users') ? 'third' : ''}
                >
                  <IonIcon icon={people} slot='start' />
                  User management
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/admin/clinics'
                  color={location?.pathname?.startsWith('/admin/clinics') ? 'third' : ''}
                >
                  <IonIcon icon={business} slot='start' />
                  Clinics
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/appointments'
                  color={location?.pathname?.startsWith('/appointments') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <IonIcon icon={calendarNumber} slot='start' />
                  Appointments
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/admin/products'
                  color={location?.pathname?.startsWith('/admin/products') ? 'third' : ''}
                >
                  <IonIcon icon={medkit} slot='start' />
                  Plant based Products
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/calendly/appt'
                  color={location?.pathname?.startsWith('/calendly/appt') ? 'third' : ''}
                >
                  <IonIcon icon={calendarClear} slot='start' />
                  Calendly appt defs
                </IonItem>
              </>
            )}
            {isTechAdmin(user) && (
              <>
                <IonItemDivider>Tech admin</IonItemDivider>
                <IonItem
                  type='button'
                  routerLink='/webhooks/calendly'
                  color={location?.pathname?.startsWith('/webhooks/calendly') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <IonIcon icon={link} slot='start' />
                  Calendly webhooks
                </IonItem>
              </>
            )}
            {isClinicAdmin(user) && (
              <>
                <IonItemDivider>Clinic admin</IonItemDivider>
                <IonItem
                  type='button'
                  routerLink='/ca/desk'
                  color={location?.pathname?.startsWith('/ca/desk') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <span
                    style={{
                      marginRight: adminClinic?.publicId ? '8px' : '9px',
                    }}
                  >
                    <ClinicStatusComp clinic={adminClinic} />
                  </span>
                  {adminClinic?.publicId?.toUpperCase()} Admin
                </IonItem>
              </>
            )}
            {isAccountant(user) && (
              <>
                <IonItemDivider>Accountant panel</IonItemDivider>
                <IonItem
                  type='button'
                  routerLink='/accountant/desk'
                  color={location?.pathname?.startsWith('/accountant/desk') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <span
                    style={{
                      marginRight: accountantClinic?.publicId ? '8px' : '9px',
                    }}
                  >
                    <ClinicStatusComp clinic={accountantClinic} />
                  </span>
                  {accountantClinic?.publicId?.toUpperCase()} Desk
                </IonItem>
              </>
            )}
            {isDoctor(user) && (
              <>
                <IonItemDivider>Doctor panel</IonItemDivider>
                <IonItem
                  type='button'
                  routerLink='/doctor/appts'
                  color={location?.pathname?.startsWith('/doctor/appts') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <IonIcon icon={calendarNumber} slot='start' />
                  Appointments
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/doctor/search'
                  color={location?.pathname?.startsWith('/doctor/search') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <IonIcon icon={people} slot='start' />
                  Patients
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/tga'
                  color={location?.pathname?.startsWith('/tga') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <IonIcon icon={pencil} slot='start' />
                  Prescribing, TGA tools
                </IonItem>
                <IonItem type='button' onClick={() => toastInfo('Comming soon')}>
                  <IonIcon icon={medkit} slot='start' />
                  Pharmaceutical products
                </IonItem>
              </>
            )}
            {isReception(user) && (
              <>
                <IonItemDivider>Receptionist panel</IonItemDivider>
                <IonItem
                  type='button'
                  routerLink='/book/fast/0'
                  color={location?.pathname?.startsWith('/book/fast/') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <span
                    style={{
                      marginRight: receptionClinic?.publicId ? '8px' : '9px',
                    }}
                  >
                    <ClinicStatusComp clinic={receptionClinic} />
                  </span>
                  Book at {receptionClinic?.publicId?.toUpperCase()}
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/reception/bookings'
                  color={location?.pathname?.startsWith('/reception/bookings') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <IonIcon icon={calendarNumber} slot='start' />
                  Bookings
                </IonItem>
                <IonItem
                  type='button'
                  routerLink='/reception/search'
                  color={location?.pathname?.startsWith('/reception/search') ? (darkMode ? 'dark' : 'third') : ''}
                >
                  <IonIcon icon={people} slot='start' />
                  Patients
                </IonItem>
              </>
            )}
            <IonItem
              routerLink='/dr/nas'
              type='button'
              color={location?.pathname?.startsWith('/booking') ? (darkMode ? 'dark' : 'third') : ''}
            >
              <IonIcon icon={calendar} slot={'start'} />
              Book an appointment
            </IonItem>
            <IonItem
              type='button'
              routerLink='/my/appts'
              color={location?.pathname?.startsWith('/my/appts') ? (darkMode ? 'dark' : 'third') : ''}
            >
              <IonIcon icon={calendarNumber} slot='start' />
              My Appointments
            </IonItem>
            <IonItem
              routerLink='/quiz'
              type='button'
              color={location?.pathname?.startsWith('/quiz') ? (darkMode ? 'dark' : 'third') : ''}
            >
              <IonIcon icon={speedometer} slot={'start'} />
              Tools
            </IonItem>
          </IonMenuToggle>
          <IonItem onClick={() => toastInfo('Comming soon')} type='button'>
            <IonIcon icon={pencil} slot='start' />
            Blog
          </IonItem>
          <IonItem routerLink='/about' type='button'>
            <IonIcon icon={peopleCircle} slot='start' />
            About us
          </IonItem>
          <IonItem onClick={() => toastInfo('Comming soon')} type='button'>
            <IonIcon icon={at} slot='start' />
            Contact us
          </IonItem>
          <IonItem>
            <IonIcon slot='start' icon={moonOutline} />
            <IonLabel>Dark mode</IonLabel>
            <IonToggle
              checked={darkMode}
              color='secondary'
              onIonChange={(ev) => {
                document.body.classList.toggle('dark', ev.detail.checked)
                setDarkMode(ev.detail.checked)
              }}
            />
          </IonItem>
          {authStatus === 'authenticated' && (
            <IonItem onClick={signOut} className='pointer'>
              <IonIcon icon={logOut} slot='start' />
              Logout
            </IonItem>
          )}
        </IonList>
      </IonContent>
      <IonFooter collapse='fade'>
        <IonToolbar>
          <span className='copyright ion-padding-start'>
            &copy; 2022, Telebinoid.com, v{process?.env?.REACT_APP_VERSION}
          </span>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    menuEnabled: state.user.menuEnabled,
  }),
  mapDispatchToProps: {
    setDarkMode,
  },
  component: withRouter(Menu),
})

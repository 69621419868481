import { UserActions } from './user.actions'
import { UserState } from './user.state'

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case 'set-net':
      return { ...state, net: action.net }
    case 'set-user-loading':
      return { ...state, loading: action.isLoading }
    case 'set-user-data':
      return { ...state, ...action.data }
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode }
    case 'set-login-mode':
      return { ...state, loginMode: action.loginMode }
    case 'set-menu-enabled':
      return { ...state, menuEnabled: action.menuEnabled }
    case 'set-consent':
      return { ...state, consent: action.consent }
    case 'set-booking':
      return { ...state, booking: action.booking }
    case 'set-patient-detail':
      return { ...state, patientDetail: action.patientDetail }
    case 'set-patient-health':
      return { ...state, patientHealth: action.patientHealth }
    case 'set-quiz':
      return { ...state, quiz: action.quiz }
    case 'set-email-sent':
      return { ...state, emailSent: action.emailSent }
    case 'set-doctor-note':
      return { ...state, doctorNote: action.doctorNote }
  }
}

import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Doctor, ModelDoctorFilterInput, UpdateDoctorInput, User } from '../../API'
import { createDoctor, updateDoctor } from '../../graphql/mutations'
import { findDoctorByUserId, listDoctors } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import hasNewChanges from '../util/hasNewChanges'
import { CurrentUserService } from './CurrentUserService'
import { TbnResponse } from './TbnResponse'

export class CurrentDoctorService {
  private static doctorService: CurrentDoctorService | undefined
  private doctor?: Doctor
  private user?: User
  constructor(user?: User) {
    this.user = user
  }

  public async currentDoctor(): Promise<Doctor> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentDoctorService.Instance.doctor) {
      return Promise.resolve(CurrentDoctorService.Instance.doctor)
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentDoctorService.Instance.doctor) {
      return Promise.resolve(CurrentDoctorService.Instance.doctor)
    } else {
      return Promise.reject('Current doctor resolve timeout!')
    }
  }

  public async list(filter?: ModelDoctorFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listDoctors,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return Promise.resolve({ data: res?.data?.listDoctors?.items, errorMessage: res?.errors?.[0]?.message })
    } catch (err) {
      warn('error fetching doctor: ', err)
      return Promise.reject(err)
    }
  }

  public async findDoctor(userID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findDoctorByUserId,
        variables: { userID },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForDoctorDuplication(res?.data?.findDoctorByUserId?.items)
      return { data: res?.data?.findDoctorByUserId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error fetching doctor: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async addOrEditDoctor(userID: string, doctor: Doctor): Promise<TbnResponse> {
    try {
      const res: TbnResponse = await this.findDoctor(userID)
      if (!!res.data) {
        return { data: this.editDoctor(res.data, doctor) }
      } else {
        return { data: this.addDoctor(userID, doctor) }
      }
    } catch (err) {
      log('error fetching doctor: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async editDoctor(previous: Doctor, doctor: Doctor) {
    const next: UpdateDoctorInput = {
      id: previous.id,
      drId: doctor.drId?.toLowerCase(),
      drName: doctor.drName,
      avatar: doctor.avatar,
      owner: previous.owner,
    }
    log('Doctor update command', next)
    if (this.includesNoNewChanges(next, previous)) {
      log('Doctor update skipped, no new changes')
      return previous
    } else {
      const res: any = await API.graphql({
        query: updateDoctor,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('Doctor updated to', res?.data?.updateDoctor)
      return res?.data?.updateDoctor
    }
  }

  public async addDoctor(userID: string, doctor: Doctor) {
    try {
      const res: any = await API.graphql({
        query: createDoctor,
        variables: {
          input: {
            userID,
            drId: doctor.drId?.toLowerCase(),
            drName: doctor.drName,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create doctor res', res)
      return res.data.createDoctor
    } catch (err) {
      log('Error creating doctor! :', err)
      return err
    }
  }

  private checkForDoctorDuplication(items?: any[]) {
    if (!!items && items.length > 1) {
      warn('FOUND DUPLICATED DOCTOR!!!', items)
    }
  }

  private includesNoNewChanges(cmd: UpdateDoctorInput, previous: Doctor) {
    return hasNewChanges(cmd, previous)
  }

  private async checkIfDoctorExists(service: CurrentDoctorService) {
    try {
      const res: any = await API.graphql({
        query: findDoctorByUserId,
        variables: { userID: service.user?.id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      const doctor = res?.data?.findDoctorByUserId?.items?.[0]
      if (!!doctor) {
        service.doctor = doctor
        log('foundByUserId', doctor)
        return service
      }
    } catch (err) {
      log('error fetching doctor: ', err)
    }
    return service
  }

  private async buildCurrentDoctorService(): Promise<CurrentDoctorService> {
    try {
      const user: User | undefined = await CurrentUserService.Instance?.currentUser()
      let service = new CurrentDoctorService(user)
      service = await service.checkIfDoctorExists(service)
      return Promise.resolve(service)
    } catch (err) {
      warn('Init CurrentDoctorService failure:', err)
      return this.buildCurrentDoctorService()
    }
  }

  public static get Instance(): CurrentDoctorService {
    if (!this.doctorService) {
      this.doctorService = new CurrentDoctorService()
      this.doctorService.buildCurrentDoctorService().then((service: CurrentDoctorService) => {
        this.doctorService = service
      })
    }
    return this.doctorService
  }
}

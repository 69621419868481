import { API, Auth } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { ModelSortDirection, QuizType, CreateQuizInput, ModelQuizFilterInput } from '../../API'
import { createQuiz } from '../../graphql/mutations'
import { findQuizByOwner, findQuizByUserId } from '../../graphql/queries'
import { log, warn } from '../util/Log'

export class QuizService {
  private static quizService: QuizService | undefined

  public async buildQuizService(): Promise<QuizService> {
    try {
      let quizService: QuizService = new QuizService()
      return Promise.resolve(quizService)
    } catch (err) {
      warn('Init QuizService failure:', err)
      return this.buildQuizService()
    }
  }

  public async findQuizByOwner(quizType: QuizType) {
    try {
      const user = await Auth.currentUserInfo()
      const filter: ModelQuizFilterInput = {
        quizType: { eq: quizType },
        givenName: { eq: user?.attributes?.given_name },
      }
      const res: any = await API.graphql({
        query: findQuizByOwner,
        variables: {
          owner: user?.attributes?.sub,
          filter,
          sortDirection: ModelSortDirection.DESC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return res?.data?.findQuizByOwner?.items
    } catch (err) {
      log('error finding quiz: ', err)
      throw err
    }
  }

  public async findQuizzes(userID: string, givenName?: string) {
    try {
      const filter: ModelQuizFilterInput = {}
      if (!!givenName) {
        filter.givenName = { eq: givenName }
      }
      const res: any = await API.graphql({
        query: findQuizByUserId,
        variables: { userID, sortDirection: ModelSortDirection.DESC, filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return res?.data?.findQuizByUserId?.items
    } catch (err) {
      log('error finding quiz: ', err)
      throw err
    }
  }

  public async findQuiz(userID: string, quizType: QuizType) {
    try {
      const user = await Auth.currentUserInfo()
      const filter: ModelQuizFilterInput = {
        quizType: { eq: quizType },
        givenName: { eq: user?.attributes?.given_name },
      }
      const res: any = await API.graphql({
        query: findQuizByUserId,
        variables: { userID, sortDirection: ModelSortDirection.DESC, filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return res?.data?.findQuizByUserId?.items
    } catch (err) {
      log('error fetching Quiz: ', err)
      throw err
    }
  }

  public async createQuiz(input: CreateQuizInput) {
    try {
      const user = await Auth.currentUserInfo()
      const res: any = await API.graphql({
        query: createQuiz,
        variables: {
          input: {
            ...input,
            givenName: user?.attributes?.given_name,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create quiz res', res)
      return res.data.createQuiz
    } catch (err) {
      log('Error creating quiz! :', err)
      throw err
    }
  }

  public static get Instance(): QuizService {
    if (!this.quizService) {
      this.quizService = new QuizService()
      this.quizService.buildQuizService().then((service: QuizService) => {
        this.quizService = service
      })
    }
    return this.quizService
  }
}

import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { MedicalAuthorisedPrescriber } from '../../API'
import { CreateMedicalAuthorisedPrescriberInput } from '../../API'
import { UpdateMedicalAuthorisedPrescriberInput } from '../../API'
import { ModelMedicalAuthorisedPrescriberFilterInput, ModelSortDirection } from '../../API'
import { createMedicalAuthorisedPrescriber, updateMedicalAuthorisedPrescriber } from '../../graphql/mutations'
import { findMedicalAuthorisedPrescriberBySorter, getMedicalAuthorisedPrescriber } from '../../graphql/queries'
import { error, log, warn } from '../util/Log'
import SORT_CHAR from '../util/Sorter'
import { TbnResponse } from './TbnResponse'

export class MedicalAuthorisedPrescriberService {
  private static service: MedicalAuthorisedPrescriberService | undefined

  public async load(id: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: getMedicalAuthorisedPrescriber,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.getMedicalAuthorisedPrescriber, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async listAll(filter?: ModelMedicalAuthorisedPrescriberFilterInput): Promise<TbnResponse> {
    try {
      const defaultFilter: ModelMedicalAuthorisedPrescriberFilterInput = {
        archived: { ne: true },
        ...filter,
      }
      const res: any = await API.graphql({
        query: findMedicalAuthorisedPrescriberBySorter,
        variables: {
          sorter: SORT_CHAR,
          filter: defaultFilter,
          sortDirection: ModelSortDirection.ASC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findMedicalAuthorisedPrescriberBySorter?.items,
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn(err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async find(map: MedicalAuthorisedPrescriber): Promise<TbnResponse> {
    try {
      const filter: ModelMedicalAuthorisedPrescriberFilterInput = {
        mapNumber: { eq: map.mapNumber?.trim() },
        doctorID: { eq: map.doctorID },
        archived: { attributeExists: true },
      }
      const list: TbnResponse = await this.listAll(filter)
      return { data: list?.data?.[0] }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async updateIndex(map: MedicalAuthorisedPrescriber): Promise<TbnResponse> {
    try {
      if (!!map.id) {
        const next: UpdateMedicalAuthorisedPrescriberInput = {
          id: map.id,
          idxOrder: map.idxOrder,
        }
        const res: any = await API.graphql({
          query: updateMedicalAuthorisedPrescriber,
          variables: { input: next },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('entity updated', res?.data?.updateMedicalAuthorisedPrescriber?.id)
        return { data: res?.data?.updateMedicalAuthorisedPrescriber }
      }
      return { errorMessage: 'MAP id is null' }
    } catch (err) {
      error('error on persist item: ', err, map)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(map: MedicalAuthorisedPrescriber | CreateMedicalAuthorisedPrescriberInput): Promise<TbnResponse> {
    try {
      const existed: TbnResponse = await this.find(map as MedicalAuthorisedPrescriber)
      if (!!map.id || !!existed?.data) {
        const next: UpdateMedicalAuthorisedPrescriberInput = {
          id: map.id || existed?.data?.id,
          mapNumber: map.mapNumber?.trim(),
          idxOrder: existed?.data?.idxOrder,
          expiryDate: map.expiryDate,
          decisionDate: map.decisionDate,
          createdDate: map.createdDate,
          status: map.status || null,
          archived: false,
          sorter: SORT_CHAR,
        }
        const res: any = await API.graphql({
          query: updateMedicalAuthorisedPrescriber,
          variables: { input: next },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('entity updated', res?.data?.updateMedicalAuthorisedPrescriber?.id)
        return { data: res?.data?.updateMedicalAuthorisedPrescriber }
      } else {
        const res: any = await API.graphql({
          query: createMedicalAuthorisedPrescriber,
          variables: { input: { ...map, sorter: SORT_CHAR, archived: false } },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('entity created', res?.data?.createMedicalAuthorisedPrescriber?.id)
        return { data: res?.data, errorMessage: res?.errors?.[0].message }
      }
    } catch (err) {
      error('error on persist item: ', err, map)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async archive(map: MedicalAuthorisedPrescriber): Promise<TbnResponse> {
    try {
      const input: UpdateMedicalAuthorisedPrescriberInput = {
        id: map.id,
        idxOrder: map.idxOrder,
        archived: true,
      }
      const res: any = await API.graphql({
        query: updateMedicalAuthorisedPrescriber,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.updateMedicalAuthorisedPrescriber, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on archive item: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): MedicalAuthorisedPrescriberService {
    if (!this.service) {
      this.service = new MedicalAuthorisedPrescriberService()
    }
    return this.service
  }
}

import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import {
  AppointmentNote,
  CreateAppointmentNoteInput,
  DeleteAppointmentNoteInput,
  ModelAppointmentNoteFilterInput,
  ModelSortDirection,
} from '../../API'
import { createAppointmentNote, deleteAppointmentNote } from '../../graphql/mutations'
import { findAppointmentNoteByAppointmentId, listAppointmentNotes } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class AppointmentNoteService {
  private static service: AppointmentNoteService | undefined

  public async findByAppointmentId(appointmentID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findAppointmentNoteByAppointmentId,
        variables: { appointmentID, sortDirection: ModelSortDirection.ASC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findAppointmentNoteByAppointmentId?.items,
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn('error fetching appointment notes: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async listAll(filter?: ModelAppointmentNoteFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listAppointmentNotes,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listAppointmentNotes?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching appointment notes: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async add(appointmentNoteCommand: CreateAppointmentNoteInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: createAppointmentNote,
        variables: { input: appointmentNoteCommand },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createAppointmentNote, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on add : ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(appointmentNote: AppointmentNote): Promise<TbnResponse> {
    try {
      const input: DeleteAppointmentNoteInput = {
        id: appointmentNote.id,
      }
      const res: any = await API.graphql({
        query: deleteAppointmentNote,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteAppointmentNote, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on remove : ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): AppointmentNoteService {
    if (!this.service) {
      this.service = new AppointmentNoteService()
    }
    return this.service
  }
}

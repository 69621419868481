import { FC, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, IonSegment, IonSegmentButton, IonText } from '@ionic/react'
import { business, call } from 'ionicons/icons'
import { AppointmentDefinition, AppointmentSubject, AppointmentType, TypeFormEvent, Who } from '../../API'
import AppointmentDefintionIcon from '../../components/models/AppointmentDefinitionIcon'
import { FollowUp } from '../../components/models/FollowUp'
import TbnAvatar from '../../components/models/TbnAvatar'
import { isDesktop } from '../../components/util/isMobile'

interface AvailableBookingsComponentProps {
  apptDefs: AppointmentDefinition[]
  drPublicId?: string | null
  clinicPublicId?: string
  history: any
  fastMode?: boolean
  onSelect?: Function
  onChange?: Function
  previousAppointment?: TypeFormEvent
}
const AvailableBookingsComponent: FC<AvailableBookingsComponentProps> = ({
  apptDefs,
  drPublicId,
  clinicPublicId,
  history,
  fastMode = false,
  onSelect,
  onChange,
  previousAppointment,
}) => {
  const [type, setType] = useState(AppointmentType.IN_PERSON) // eslint-disable-line
  const [existed, setExisted] = useState('false')
  const [followUp, setFollowUp] = useState(FollowUp.FIRST)
  const [who, setWho] = useState(fastMode ? Who.SOELSE : Who.MYSELF)

  const filteredList = () => {
    let res = apptDefs
    res = res.filter((i: AppointmentDefinition) => i.drPublicId === drPublicId)
    res = res.filter((i: AppointmentDefinition) => i.clinicPublicId === clinicPublicId)
    res = res.filter((i: AppointmentDefinition) => i.appointmentType === type)
    if (existed === 'false') {
      res = res.filter((i: AppointmentDefinition) => i.appointmentSubject === AppointmentSubject.INITIAL_CONSULTATION)
    } else {
      res = res.filter((i: AppointmentDefinition) => i.appointmentSubject !== AppointmentSubject.INITIAL_CONSULTATION)
      switch (followUp) {
        case FollowUp.FIRST:
          res = res.filter((i: AppointmentDefinition) => i.appointmentSubject === AppointmentSubject.FIRST_FOLLOW_UP)
          return res
        case FollowUp.LONG:
          res = res.filter((i: AppointmentDefinition) => i.appointmentSubject === AppointmentSubject.LONG_FOLLOW_UP)
          return res
        case FollowUp.USUAL:
          res = res.filter((i: AppointmentDefinition) => i.appointmentSubject === AppointmentSubject.USUAL_FOLLOW_UP)
          return res
      }
    }
    return res
  }

  useEffect(() => {
    if (!!previousAppointment?.definitionType) {
      setType(previousAppointment.definitionType)
    }
    if (!!previousAppointment?.definitionSubject) {
      setExisted('true')
      switch (previousAppointment.definitionSubject) {
        case AppointmentSubject.FIRST_FOLLOW_UP:
          setFollowUp(FollowUp.USUAL)
          return
        case AppointmentSubject.LONG_FOLLOW_UP:
          setFollowUp(FollowUp.USUAL)
          return
        case AppointmentSubject.USUAL_FOLLOW_UP:
          setFollowUp(FollowUp.USUAL)
          return
        case AppointmentSubject.INITIAL_CONSULTATION:
          setFollowUp(FollowUp.FIRST)
          return
      }
    }
  }, [previousAppointment])

  useEffect(() => {
    if (existed === 'false') {
      setFollowUp(FollowUp.FIRST)
    }
  }, [existed]) // eslint-disable-line

  useEffect(() => {
    if (!!onChange) onChange(undefined)
  }, [type, existed, followUp, who, drPublicId, clinicPublicId]) // eslint-disable-line

  return (
    <>
      {!fastMode && (
        <>
          <h6 style={{ marginTop: '16px' }}>Who is this appointment for?</h6>
          <IonSegment value={who} onIonChange={(e: any) => setWho(e.detail.value)}>
            <IonSegmentButton value={Who.MYSELF}>My self</IonSegmentButton>
            <IonSegmentButton value={Who.SOELSE}>Someone else</IonSegmentButton>
          </IonSegment>
        </>
      )}
      <h6>Are you a new or an existing patient?</h6>
      <IonSegment value={existed} onIonChange={(e: any) => setExisted(e.detail.value)}>
        <IonSegmentButton value={'false'}>New patient</IonSegmentButton>
        <IonSegmentButton value={'true'}>Existing patient</IonSegmentButton>
      </IonSegment>
      {existed === 'true' && (
        <>
          <h6>What type of a consultation do you need? </h6>
          <IonSegment value={followUp} onIonChange={(e: any) => setFollowUp(e.detail.value)}>
            <IonSegmentButton value={FollowUp.FIRST}>First follow up</IonSegmentButton>
            <IonSegmentButton value={FollowUp.USUAL}>Usual {isDesktop() ? 'follow up' : ''}</IonSegmentButton>
            <IonSegmentButton value={FollowUp.LONG}>Long {isDesktop() ? 'follow up' : ''}</IonSegmentButton>
          </IonSegment>
          {followUp === FollowUp.FIRST && (
            <IonText color='medium'>This is your first review following the initial consult.</IonText>
          )}
          {followUp === FollowUp.LONG && (
            <IonText color='medium'>
              If you think you need a little longer to discuss a few things, there are updates on your health you need
              to inform your doctor or you believe changes are necessary to your treatment, please choose this one.
            </IonText>
          )}
          {followUp === FollowUp.USUAL && (
            <IonText color='medium'>If things are going well you can book for a short consult.</IonText>
          )}
        </>
      )}
      <h6>How would you prefer to have your consult?</h6>
      <IonSegment value={type} onIonChange={(e: any) => setType(e.detail.value)}>
        <IonSegmentButton value={AppointmentType.IN_PERSON} layout='icon-start'>
          <IonIcon icon={business}></IonIcon>
          <IonLabel>In-Person (Clinic)</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={AppointmentType.TELEHEALTH} layout='icon-start'>
          <IonIcon icon={call}></IonIcon>
          <IonLabel>Telehealth</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {type === AppointmentType.IN_PERSON && <IonText color='medium'>I will attend the clinic in person.</IonText>}
      {type === AppointmentType.TELEHEALTH && (
        <IonText color='medium'>I would like to have a telehealth consult.</IonText>
      )}
      <div className='ion-margin-top ion-padding-top'>
        {filteredList().length === 0 && <IonText color={'danger'}>No booking existed for above criteria</IonText>}
        {filteredList().map((itm: AppointmentDefinition) => (
          <IonItem
            key={itm.id}
            button={true}
            onClick={() => {
              if (!fastMode) history.push(`/booking/${itm?.id}${who === Who.SOELSE ? '?else=y' : ''}`)
              else if (!!onSelect) onSelect(itm)
            }}
            mode={!fastMode ? 'ios' : 'md'}
          >
            <AppointmentDefintionIcon model={itm} />
            <IonLabel className='ion-text-wrap'>
              {itm?.calendlyAppointment?.name}
              {itm?.clinicName && (
                <>
                  <IonText className='ion-padding-horizontal' color='tertiary'>
                    {itm?.clinicName}
                  </IonText>
                  Clinic
                </>
              )}
            </IonLabel>
            {fastMode && <TbnAvatar avatar={itm?.drAvatar} slot='end' />}
            {!fastMode && <IonButton slot='end'>Book Appointment</IonButton>}
          </IonItem>
        ))}
      </div>
    </>
  )
}

export default AvailableBookingsComponent

import { FC, useEffect, useState } from 'react'
import { FaSortDown, FaSortUp } from 'react-icons/fa'
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonRow, IonText } from '@ionic/react'
import { refresh } from 'ionicons/icons'
import { PatientLink } from '../../../API'
import { BookingService } from '../../../components/services/BookingService'
import { PatientLinkService } from '../../../components/services/PatientLinkService'
import { defaultTimezone, utcToLocale } from '../../../components/util/Date'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'

interface OwnProps {
  clinicID: string
}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface PatientSearchProps extends OwnProps, StateProps, DispatchProps {}
const PatientSearch: FC<PatientSearchProps> = ({ clinicID, setNet, net }) => {
  const [items, setItems] = useState<PatientLink[]>([])
  const [searchItems, setSearchItems] = useState<PatientLink[]>([])
  const [givenName, setGivenName] = useState<string>()
  const [surname, setSurname] = useState<string>()
  const [givenNameSort, setGivenNameSort] = useState<boolean | undefined>(undefined)
  const [surnameSort, setSurnameSort] = useState<boolean | undefined>(undefined)
  const [birthdaySort, setBirthdaySort] = useState<boolean | undefined>(undefined)
  const [nextApptSort, setNextApptSort] = useState<boolean | undefined>(undefined)

  const fetchItems = async () => {
    setNet(true)
    const res = await PatientLinkService.Instance.list({ clinicID: { eq: clinicID } })
    if (res.data) {
      setItems(res.data)
      setSearchItems(res.data)
    }
    setNet(false)
  }

  const lookupLastAppointment = (patientID: string) => {
    BookingService.Instance.lastAppointmentFor(patientID).then().catch()
    return BookingService.Instance.cache?.lastAppts?.patientID?.startTime
      ? `${utcToLocale(BookingService.Instance.cache?.lastAppts?.patientID?.startTime)} (${defaultTimezone()})`
      : '...'
  }

  useEffect(() => {
    const reverse = !nextApptSort ? 1 : -1
    searchItems.sort(
      (a: PatientLink, b: PatientLink) =>
        reverse *
        (BookingService.Instance.cache.lastAppts?.[a.patientID]?.startTime?.localeCompare(
          BookingService.Instance.cache?.lastAppts?.[b.patientID]?.startTime || '',
        ) || 0),
    )
  }, [nextApptSort]) // eslint-disable-line

  useEffect(() => {
    const reverse = !birthdaySort ? 1 : -1
    searchItems.sort(
      (a: PatientLink, b: PatientLink) =>
        reverse * (a.patient?.dateOfBirth?.localeCompare(b.patient?.dateOfBirth || '') || 0),
    )
  }, [birthdaySort]) // eslint-disable-line

  useEffect(() => {
    const reverse = !surnameSort ? 1 : -1
    searchItems.sort(
      (a: PatientLink, b: PatientLink) => reverse * (a.patient?.surName?.localeCompare(b.patient?.surName || '') || 0),
    )
  }, [surnameSort]) // eslint-disable-line

  useEffect(() => {
    const reverse = !givenNameSort ? 1 : -1
    searchItems.sort(
      (a: PatientLink, b: PatientLink) =>
        reverse * (a.patient?.givenName?.localeCompare(b.patient?.givenName || '') || 0),
    )
  }, [givenNameSort]) // eslint-disable-line

  useEffect(() => {
    setSearchItems(
      items.filter(
        (itm: PatientLink) =>
          (!givenName || itm?.patient?.givenName?.toLowerCase()?.includes(givenName?.toLowerCase())) &&
          (!surname || itm?.patient?.surName?.toLowerCase()?.includes(surname?.toLowerCase())),
      ),
    )
  }, [givenName, surname]) // eslint-disable-line

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonInput
              value={givenName}
              onIonChange={(e: any) => setGivenName(e.detail.value)}
              placeholder='Given name'
            />
          </IonItem>
        </IonCol>
        <IonCol size='2' className='ion-text-end'>
          <IonButton
            fill='clear'
            disabled={net}
            onClick={() => {
              setGivenName('')
              setSurname('')
              fetchItems()
            }}
          >
            <IonIcon icon={refresh} />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonInput value={surname} onIonChange={(e: any) => setSurname(e.detail.value)} placeholder='Surname' />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className='tr-header'>
        <IonCol onClick={() => setGivenNameSort(!givenNameSort)} className='pointer'>
          Given name
          {givenNameSort === true && <FaSortUp />}
          {givenNameSort === false && <FaSortDown />}
        </IonCol>
        <IonCol onClick={() => setSurnameSort(!surnameSort)} className='pointer'>
          Surname
          {surnameSort === true && <FaSortUp />}
          {surnameSort === false && <FaSortDown />}
        </IonCol>
        <IonCol onClick={() => setBirthdaySort(!birthdaySort)} className='pointer'>
          Date of Birth
          {birthdaySort === true && <FaSortUp />}
          {birthdaySort === false && <FaSortDown />}
        </IonCol>
        <IonCol onClick={() => setNextApptSort(!nextApptSort)} className='pointer'>
          Next Appointment
          {nextApptSort === true && <FaSortUp />}
          {nextApptSort === false && <FaSortDown />}
        </IonCol>
      </IonRow>
      {searchItems.map((itm: PatientLink) => (
        <IonRow key={itm.doctorID + itm.patientID + itm.clinicID}>
          <IonCol>{itm.patient?.givenName}</IonCol>
          <IonCol>{itm.patient?.surName}</IonCol>
          <IonCol>{itm.patient?.dateOfBirth}</IonCol>
          <IonCol>
            {!!BookingService.Instance.cache.lastAppts?.[itm.patientID]?.id && (
              <IonItem
                button={true}
                routerLink={`/book/fast/${BookingService.Instance.cache.lastAppts?.[itm.patientID]?.id}`}
              >
                {`${utcToLocale(
                  BookingService.Instance.cache?.lastAppts?.[itm.patientID].startTime,
                )} (${defaultTimezone()})`}
              </IonItem>
            )}
            {!BookingService.Instance.cache.lastAppts?.[itm.patientID]?.id && (
              <IonText>{lookupLastAppointment(itm?.patientID)}</IonText>
            )}
          </IonCol>
        </IonRow>
      ))}
    </IonGrid>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: PatientSearch,
})

import { ProductPresentation } from '../../API'
import { warn } from '../util/Log'

const productPresentationReverse = (presentation: ProductPresentation) => {
  if (!!presentation) {
    switch (presentation) {
      case ProductPresentation.CAPSULE:
        return 'Capsule'
      case ProductPresentation.ORAL_LIQUID:
        return 'Oral Liquid'
      case ProductPresentation.DRIED_HERB:
        return 'Dried Herb'
      case ProductPresentation.SPRAY_SOLUTION:
        return 'Spray, solution'
    }
  }
  warn('NO MATCH FOUND for', presentation)
  return null
}

export default productPresentationReverse

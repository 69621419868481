export const getPatientLink = /* GraphQL */ `
  query GetPatientLink($doctorID: ID!, $patientID: ID!) {
    getPatientLink(doctorID: $doctorID, patientID: $patientID) {
      doctorID
      patientID
      patient {
        id
        email
        phone
        givenName
        surName
        dateOfBirth
      }
      clinicID
    }
  }
`

export const findTypeFormEventsByPatientId = /* GraphQL */ `
  query FindTypeFormEventsByPatientId(
    $patientID: ID!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsByPatientId(
      patientID: $patientID
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startTime
      }
      nextToken
    }
  }
`

export const listPatientLinks = /* GraphQL */ `
  query ListPatientLinks(
    $doctorID: ID
    $patientID: ModelIDKeyConditionInput
    $filter: ModelPatientLinkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPatientLinks(
      doctorID: $doctorID
      patientID: $patientID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        doctorID
        patientID
        patient {
          givenName
          surName
          dateOfBirth
        }
        clinicID
      }
      nextToken
    }
  }
`

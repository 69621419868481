import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonText, useIonViewDidLeave } from '@ionic/react'
import { IonRow, IonSpinner, useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { TabItem, Tabs } from '@aws-amplify/ui-react'
import { calendar, card, documentOutline } from 'ionicons/icons'
import { ConsentEvent, Patient, TypeFormEvent } from '../../API'
import appointmentSubjectLabel from '../../components/models/AppointmentSubjectLabel'
import AppointmentTypeIcon from '../../components/models/AppointmentTypeIcon'
import { BookingService } from '../../components/services/BookingService'
import { PatientLinkService } from '../../components/services/PatientLinkService'
import { defaultTimezone, utcToLocale } from '../../components/util/Date'
import { isDesktop } from '../../components/util/isMobile'
import { getTypeFormEvent } from '../../graphql/queries'
import PatientQuizView from '../forms/PatientQuizView'
import ConsentFormView from '../forms/consent/ConsentFormView'
import AnswerModal from './AnswerModal'
import AppointmentMed from './AppointmentMed'
import AppointmentNote from './AppointmentNote'
import DoctorContainer from './DoctorContainer'
import DoctorPatientDetails from './DoctorPatientDetails'
import DoctorPatientHealth from './DoctorPatientHealth'
import DoctorPrescription from './DoctorPrescription'

const AppointmentDetail = () => {
  const { id } = useParams<any>()
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState<TypeFormEvent | null | undefined>()
  const [present] = useIonToast()
  const [showModal, setShowModal] = useState(false)
  const [doubleCheck, setDoubleCheck] = useState(true)
  const [patient, setPatient] = useState<Patient | null>()
  const [consentLoaded, setConsentLoaded] = useState<boolean>(false)

  const fetchItem = async () => {
    setLoading(true)
    try {
      const variables = {
        id,
      }
      const res: any = await API.graphql({
        query: getTypeFormEvent,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      setItem(res?.data?.getTypeFormEvent)
      if (!!res?.data?.getTypeFormEvent?.patient) setPatient(res.data.getTypeFormEvent.patient)
      setLoading(false)
    } catch (err: any) {
      present({ message: JSON.stringify(err), color: 'danger', duration: 3000 })
      setLoading(false)
    }
  }

  const onConsentLoaded = async (consent: ConsentEvent) => {
    setConsentLoaded(false)
    if (!!consent?.patient?.id && !!item) {
      if (!item?.patientID) {
        await BookingService.Instance.connectPatientToAppointment(item, consent.patient)
        setPatient(consent?.patient)
      }
      if (!!item?.doctorID)
        await PatientLinkService.Instance.store({
          doctorID: item?.doctorID,
          patientID: item?.patientID || consent.patient.id,
          clinicID: item?.clinicID,
        })
    }
    setTimeout(() => {
      setConsentLoaded(true)
    }, 1000)
  }

  const onPatientLoaded = async (patient: Patient) => {
    if (!!patient?.id && !!item) {
      if (!item?.patientID) {
        await BookingService.Instance.connectPatientToAppointment(item, patient)
        setPatient(patient)
      }
      if (!!item.doctorID)
        await PatientLinkService.Instance.store({
          doctorID: item?.doctorID,
          patientID: item?.patientID || patient.id,
          clinicID: item?.clinicID,
        })
    }
  }

  useIonViewDidLeave(() => {
    setDoubleCheck(true)
    setPatient(undefined)
  })

  useEffect(() => {
    if (!!id) {
      fetchItem()
    }
  }, [id]) // eslint-disable-line

  return (
    <DoctorContainer id='appointment-detail' padding={true}>
      {loading && (
        <div className='ion-text-center ion-padding'>
          <IonSpinner />
        </div>
      )}
      {!loading && !!item && (
        <IonGrid className='ion-no-padding'>
          <IonRow>
            <IonCol>
              {doubleCheck ? (
                <b
                  className='pointer'
                  onClick={() => {
                    setPatient(undefined)
                    setDoubleCheck(false)
                  }}
                >
                  {item.firstName}
                </b>
              ) : (
                <span
                  onClick={() => {
                    setPatient(undefined)
                    setDoubleCheck(true)
                  }}
                  className='pointer'
                >
                  {item.firstName}
                </span>
              )}
              , {item.email} {item.phone}
            </IonCol>
            <IonCol>
              {utcToLocale(item.endTime)} ({defaultTimezone()})
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <AppointmentTypeIcon item={item} />
              {appointmentSubjectLabel(item.definitionSubject)}
              <IonText color={'tertiary'} className='ion-padding-horizontal'>
                {item?.clinic?.name}
              </IonText>
            </IonCol>
            <IonCol>
              {utcToLocale(item.startTime)} ({defaultTimezone()})
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {item.rescheduled && <IonText color='warning'>Rescheduled</IonText>}
              {item.canceled && <IonText color='danger'>Canceled: {item.cancelReason}</IonText>}
            </IonCol>
            <IonCol>
              <IonButtons>
                <IonButton
                  fill='clear'
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  <IonIcon icon={documentOutline} slot='icon-only'></IonIcon>
                </IonButton>
                {item.paymentSuccess && (
                  <IonButton fill='clear' color='success' title={'A$ ' + item.paymentAmount}>
                    <IonIcon icon={card} slot='icon-only'></IonIcon>
                  </IonButton>
                )}
                <IonButton
                  fill='clear'
                  onClick={() =>
                    window.open(item?.bookingUrl?.replace('https://api.calendly.com', 'https://calendly.com'), '_blank')
                  }
                >
                  <IonIcon icon={calendar} slot='icon-only'></IonIcon>
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <AppointmentNote appointment={item} patient={patient} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              {!!item && (
                <Tabs spacing='equal'>
                  <TabItem title={isDesktop() ? 'Consent Form' : 'CF'}>
                    <ConsentFormView
                      email={item.email}
                      phone={item.phone}
                      givenName={item.firstName}
                      doubleCheck={doubleCheck}
                      onLoad={onConsentLoaded}
                    />
                  </TabItem>
                  <TabItem title={isDesktop() ? 'Prescription' : 'P'}>
                    {consentLoaded && !!patient && (
                      <div className='ion-padding'>
                        <AppointmentMed appointment={item} patient={patient} />
                        <DoctorPrescription patient={patient} appointment={item} />
                      </div>
                    )}
                  </TabItem>
                  <TabItem title={isDesktop() ? 'Patient Details' : 'PD'}>
                    {consentLoaded && !!item.phone && (
                      <DoctorPatientDetails
                        givenName={item.firstName}
                        phone={item.phone}
                        email={item.email}
                        patientID={patient?.id}
                        onLoad={onPatientLoaded}
                      />
                    )}
                  </TabItem>
                  <TabItem title={isDesktop() ? 'Patient Health' : 'PH'}>
                    {consentLoaded && !!item.phone && (
                      <DoctorPatientHealth
                        givenName={item.firstName}
                        phone={item.phone}
                        email={item.email}
                        patientID={patient?.id}
                        onLoad={onPatientLoaded}
                      />
                    )}
                  </TabItem>
                  <TabItem title={isDesktop() ? 'Quiz' : 'QZ'}>
                    <PatientQuizView
                      givenName={item.firstName}
                      email={item.email}
                      phone={item.phone}
                      doubleCheck={doubleCheck}
                    />
                  </TabItem>
                </Tabs>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {!!item && <AnswerModal model={item} showModal={showModal} setShowModal={setShowModal} />}
    </DoctorContainer>
  )
}

export default AppointmentDetail

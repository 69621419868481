import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonCol, IonDatetime } from '@ionic/react'
import { IonDatetimeButton, IonGrid, IonIcon, IonLabel, IonModal } from '@ionic/react'
import { IonRow, IonText, useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { cloudDownload, refresh } from 'ionicons/icons'
import { AppointmentMedication, Doctor, ModelAppointmentMedicationFilterInput, ModelSortDirection } from '../../../API'
import productCategoryLabel from '../../../components/models/ProductCategoryLabel'
import productCategoryProfile from '../../../components/models/ProductCategoryProfile'
import { convertISOtoDateFormat1 } from '../../../components/util/Date'
import groupByProp from '../../../components/util/GroupByProp'
import { failure } from '../../../components/util/Toast'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import { findAppointmentMedicationByDoctorId } from '../../../graphql-custom/reports/queries'
import Tga6Stat from './Tga6Stat'

interface OwnProps {
  doctor: Doctor
}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface Tga6ReportProps extends OwnProps, StateProps, DispatchProps {}
const Tga6Report: FC<Tga6ReportProps> = ({ setNet, doctor, net }) => {
  const [items, setItems] = useState([])
  const [nextNextToken, setNextNextToken] = useState(undefined)
  const [presentToast] = useIonToast()
  const [timeFrom, setTimeFrom] = useState<string | null>('2022-06-01T00:00:00-00:00')
  const [timeTo, setTimeTo] = useState<string | null>('2022-12-31T23:59:59-59:59')
  const [downloading, setDownloading] = useState(false)
  const [mapNums, setMapNums] = useState<any>([])
  const [stat, setStat] = useState<any>([])

  const fetchItems = async () => {
    setNet(true)
    if (!!timeFrom && !!timeTo) {
      const filter: ModelAppointmentMedicationFilterInput = {}
      const variables = {
        doctorID: doctor?.id,
        filter,
        createdAt: { between: [timeFrom, timeTo] },
        sortDirection: ModelSortDirection.ASC,
      }
      try {
        const res: any = await API.graphql({
          query: findAppointmentMedicationByDoctorId,
          variables,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        setItems(res?.data?.findAppointmentMedicationByDoctorId?.items || [])
        setNextNextToken(res?.data?.findAppointmentMedicationByDoctorId?.nextToken)
      } catch (err) {
        failure(JSON.stringify(err), presentToast)
      }
    }
    setNet(false)
  }

  const downloadCSV = () => {
    setDownloading(true)
    const rows: any[] = []
    rows.push([
      'Authorised Prescriber',
      'Profile profile',
      'Product Presentation',
      'Number of new patients commenced on treatment or number of devices supplied',
      'Number of total patients treated during this period',
    ])
    for (let itm of mapNums) {
      console.log(itm?.[0], itm?.[1])
      rows.push([
        itm?.[0],
        `${productCategoryLabel(itm?.[1]?.[0]?.product?.category)} - ${productCategoryProfile(
          itm?.[1]?.[0]?.product?.category,
        )}`,
        itm?.[1]?.[0]?.product?.presentationText,
        itm?.[1]?.length,
        itm?.[1]?.length,
      ])
    }
    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e: any) => e.join(',')).join('\n')
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `TGA6-${timeFrom?.substring(0, 10)}_${timeTo?.substring(0, 10)}.csv`)
    document.body.appendChild(link) // Required for FF
    link.click()
    setDownloading(false)
  }

  useEffect(() => {
    Array.from(mapNums).map((group: any, idx: number) => {
      stat[idx] = Array.from(groupByProp(group[1], (itm: AppointmentMedication) => itm?.patientID))
      return stat
    })
    setStat(stat)
  }, [mapNums]) // eslint-disable-line

  useEffect(() => {
    setMapNums(groupByProp(items, (itm: AppointmentMedication) => itm.map?.mapNumber))
  }, [items]) // eslint-disable-line

  useEffect(() => {
    fetchItems()
  }, [timeFrom, timeTo]) // eslint-disable-line

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='1' className='ion-text-end'>
          From
        </IonCol>
        <IonCol>
          <IonDatetimeButton datetime='timeFrom'></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonLabel className='ion-text-center'>From (UTC timezone)</IonLabel>
            <IonDatetime
              value={timeFrom}
              id='timeFrom'
              showDefaultButtons={true}
              onIonChange={(e) => setTimeFrom(e.detail.value as string)}
            ></IonDatetime>
          </IonModal>
        </IonCol>
        <IonCol size='1' className='ion-text-end'>
          To
        </IonCol>
        <IonCol>
          <IonDatetimeButton datetime='timeTo'></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonLabel className='ion-text-center'>To (UTC timezone)</IonLabel>
            <IonDatetime
              value={timeTo}
              id='timeTo'
              showDefaultButtons={true}
              onIonChange={(e) => setTimeTo(e.detail.value as string)}
            ></IonDatetime>
          </IonModal>
        </IonCol>
        <IonCol size='2' className='ion-text-end'>
          <IonButtons>
            {items?.length > 0 && (
              <IonButton fill='clear' title='CSV download' onClick={downloadCSV} disabled={downloading}>
                <IonIcon icon={cloudDownload} slot='icon-only' />
              </IonButton>
            )}
            <IonButton fill='clear' disabled={net} onClick={fetchItems}>
              <IonIcon icon={refresh} slot='icon-only' />
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
      {!!nextNextToken && (
        <IonText color='danger'>
          Number of appointments exceed the limit, please deacrease duration between dates.
        </IonText>
      )}
      <div className='ion-margin-top'></div>
      <IonRow>
        <IonCol>
          <strong>Prescriber Name</strong>
        </IonCol>
        <IonCol>
          {doctor?.drName} ({doctor?.prescriberNumber})
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <strong>Reporting Period</strong>
        </IonCol>
        <IonCol>
          {convertISOtoDateFormat1(timeFrom?.substring(0, 10))} - {convertISOtoDateFormat1(timeTo?.substring(0, 10))}
        </IonCol>
      </IonRow>
      <Tga6Stat items={items} />
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: Tga6Report,
})

import { FC, useEffect, useState } from 'react'
import { IonButton, IonSpinner, useIonToast } from '@ionic/react'
import { Patient } from '../../API'
import { PatientService } from '../../components/services/PatientService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { log } from '../../components/util/Log'
import { failure, success } from '../../components/util/Toast'
import { connect } from '../../data'
import { setNet, setPatientHealth } from '../../data/user/user.actions'
import questions from '../forms/healthQuestions.json'
import QuestionType from '../forms/helpers/QuestionType'

interface OwnProps {
  onLoad: Function
  givenName: string
  phone: string
  email?: string | null
  patientID?: string | null
}
interface StateProps {
  patientHealth: Patient
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
  setPatientHealth: typeof setPatientHealth
}
interface DoctorPatientHealthProp extends OwnProps, StateProps, DispatchProps {}
const DoctorPatientHealth: FC<DoctorPatientHealthProp> = ({
  givenName,
  phone,
  patientID,
  setNet,
  onLoad,
  net,
  patientHealth,
  email,
  setPatientHealth,
}) => {
  const [patientLoaded, setPatientLoaded] = useState(false)
  const [patient, setPatient] = useState<Patient>()
  const [present] = useIonToast()
  const healthQuestions = questions.questions

  const loadPatientHealth = async () => {
    setPatientLoaded(false)
    if (!!patientID) {
      const res: TbnResponse = await PatientService.Instance.get(patientID)
      if (!!res.data) {
        log('DoctorPatientHealth loaded', res.data)
        setPatient(res.data)
        setPatientHealth({ ...res.data, __typename: 'Patient' })
        onLoad(res.data)
      } else if (!!res.errorMessage) {
        failure(res.errorMessage, present)
      }
    } else {
      const res: TbnResponse = await PatientService.Instance.findByPhoneAndGivenName(phone, givenName)
      if (!!res.data) {
        setPatient(res.data)
        setPatientHealth({ ...res.data, __typename: 'Patient' })
        onLoad(res.data)
      } else if (!!res.errorMessage) {
        failure(res.errorMessage, present)
      } else {
        setPatient({ __typename: 'Patient', givenName, phone, email } as Patient)
        setPatientHealth({ __typename: 'Patient', givenName, phone, email } as Patient)
        onLoad(undefined)
      }
    }
    setPatientLoaded(true)
  }

  const storePatientHealth = async () => {
    setNet(true)
    const res: TbnResponse = await PatientService.Instance.store(patient)
    if (!!res.data) {
      setPatient(res.data)
      success('Patient health updated successfully', present)
    } else if (res.errorMessage) failure(res.errorMessage, present)
    setNet(false)
  }

  useEffect(() => {
    if (!!patientHealth) {
      setPatient({
        ...patient,
        primaryDiagnosis: patientHealth.primaryDiagnosis,
        otherTreatments: patientHealth.otherTreatments,
        otherMedications: patientHealth.otherMedications,
        selfMedicated: patientHealth.selfMedicated,
        kidneyLiver: patientHealth.kidneyLiver,
        cardiovascular: patientHealth.cardiovascular,
        substanceAbuseDisorder: patientHealth.substanceAbuseDisorder,
        allergies: patientHealth.allergies,
        smoke: patientHealth.smoke,
        drinkAlcohol: patientHealth.drinkAlcohol,
        otherConditions: patientHealth.otherConditions,
      } as Patient)
    }
  }, [patientHealth]) // eslint-disable-line

  useEffect(() => {
    loadPatientHealth()
  }, [patientID]) // eslint-disable-line

  return (
    <>
      {!patientLoaded && (
        <div className='ion-text-center'>
          <IonSpinner />
        </div>
      )}
      {patientLoaded && !!patientHealth && (
        <>
          {healthQuestions.map((q: any) => (
            <QuestionType question={q} model={patientHealth} key={q.id} labelOutside={false} />
          ))}
          <IonButton onClick={storePatientHealth} disabled={net} expand='block'>
            Save
          </IonButton>
        </>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    patientHealth: state.user.patientHealth,
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setPatientHealth,
    setNet,
  },
  component: DoctorPatientHealth,
})

import { FC, useEffect, useState } from 'react'
import { IonChip, IonCol, IonGrid, IonIcon, IonRow, IonText, useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { checkboxOutline, stopOutline } from 'ionicons/icons'
import { ConsentEvent, ModelConsentEventFilterInput, ModelSortDirection } from '../../../API'
import Spinner from '../../../components/helpers/Spinner'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { UserService } from '../../../components/services/UserService'
import { utcToLocale } from '../../../components/util/Date'
import { error } from '../../../components/util/Log'
import { failure } from '../../../components/util/Toast'
import { findConsentEventByUserId } from '../../../graphql/queries'
import PatientDetailsView from '../PatientDetailsView'
import PatientHealthView from '../PatientHealthView'

interface OwnProps {
  doubleCheck: boolean
  email?: string | null
  phone?: string | null
  givenName: string | null
  onLoad: Function
}
interface ConsentFormViewProps extends OwnProps {}
const ConsentFormView: FC<ConsentFormViewProps> = ({ email, phone, givenName, doubleCheck, onLoad }) => {
  const [loading, setLoading] = useState(true)
  const [consent, setConsent] = useState<ConsentEvent>()
  const [section, setSection] = useState('details')
  const [present] = useIonToast()

  const findConsent = async () => {
    setLoading(true)
    try {
      let user: TbnResponse | undefined = await UserService.Instance?.findByPhoneOrEmail(phone, email)
      if (!!user?.data?.id) {
        const filter: ModelConsentEventFilterInput = {}
        if (doubleCheck) {
          filter.givenName = { eq: givenName }
        }
        const res: any = await API.graphql({
          query: findConsentEventByUserId,
          variables: { userID: user?.data?.id, sortDirection: ModelSortDirection.DESC, filter },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        if (!!res.data.findConsentEventByUserId.items[0]) {
          setConsent(res.data.findConsentEventByUserId.items[0])
          onLoad(res.data.findConsentEventByUserId.items[0])
        } else {
          onLoad(undefined)
        }
      } else {
        onLoad(undefined)
      }
    } catch (err: any) {
      error(err)
      failure(`Error: ${err?.errors?.[0]?.message}`, present)
      onLoad(undefined)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!!email || !!phone) {
      findConsent()
    }
  }, [email, phone, doubleCheck]) // eslint-disable-line

  return (
    <>
      {loading && <Spinner />}
      {!loading && !!consent && (
        <IonGrid>
          <IonRow>
            <IonCol>Acknowledged by</IonCol>
            <IonCol>{consent?.consenter}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Acknowledged at</IonCol>
            <IonCol>{utcToLocale(consent?.createdAt)}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Research allowed</IonCol>
            <IonCol>
              {consent?.researchAllowed && <IonIcon icon={checkboxOutline} />}
              {!consent?.researchAllowed && <IonIcon icon={stopOutline} />}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <IonChip color={`${section === 'details' ? 'dark' : 'medium'}`} onClick={() => setSection('details')}>
                Patient Details
              </IonChip>
              <IonChip color={`${section === 'healths' ? 'dark' : 'medium'}`} onClick={() => setSection('healths')}>
                Patient Health
              </IonChip>
              {section === 'details' && (
                <PatientDetailsView
                  patient={JSON.parse(consent?.consentContent).patientDetail}
                  givenName={consent?.givenName}
                  doubleCheck={doubleCheck}
                />
              )}
              {section === 'healths' && (
                <PatientHealthView
                  patient={JSON.parse(consent?.consentContent).patientHealth}
                  givenName={consent?.givenName}
                  doubleCheck={doubleCheck}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {!loading && !consent && (
        <div className='ion-padding ion-margin ion-text-start'>
          <IonText color='danger'>Consent Form not found!</IonText>
        </div>
      )}
    </>
  )
}

export default ConsentFormView

import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonChip, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react'
import { IonLabel, IonList, IonText, useIonAlert, useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { add, refresh, trashBin } from 'ionicons/icons'
import { AppointmentMedication, CreateAppointmentMedicationInput, Doctor, ProductMap } from '../../API'
import { Patient, TypeFormEvent } from '../../API'
import ProductCategoryBadge from '../../components/models/ProductCategoryBadge'
import ProductPresentationIcon from '../../components/models/ProductPresentationIcon'
import MapNumberSelect from '../../components/select/MapNumberSelect'
import { AppointmentMedicationService } from '../../components/services/AppointmentMedicationService'
import { CurrentDoctorService } from '../../components/services/CurrentDoctorService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { isDoctor } from '../../components/util/Auth'
import { convertISOtoDateFormat1, isBeforeToday } from '../../components/util/Date'
import { warn } from '../../components/util/Log'
import { failure } from '../../components/util/Toast'
import { isMobile } from '../../components/util/isMobile'

interface AppointmentMedProps {
  appointment: TypeFormEvent
  patient?: Patient | null
}
const AppointmentMed: FC<AppointmentMedProps> = ({ appointment, patient }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [mapSelected, setMapSelected] = useState<ProductMap>()
  const [items, setItems] = useState([])
  const [presentToast] = useIonToast()
  const [presentAlert] = useIonAlert()
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [dailyDose, setDailyDose] = useState<string>()
  const [quantity, setQuantity] = useState<string>()
  const [repeats, setRepeats] = useState<string>()
  const [repeatInterval, setRepeatInterval] = useState<string>()
  const [hide, setHide] = useState(false)

  const fetchItems = async () => {
    const res: TbnResponse = await AppointmentMedicationService.Instance.findByAppointmentId(appointment.id)
    if (!!res.data) setItems(res.data)
    else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
  }

  const addToAppointment = async () => {
    const isAllowed = await isActualDoctor()
    if (!isAllowed) {
      failure('This appointment belongs to another doctor', presentToast)
      return
    }
    setAdding(true)
    const doctorID = appointment.doctorID || appointment?.definition?.doctorID || mapSelected?.doctorID
    if (!!mapSelected && !!doctorID && !!patient?.id) {
      const cmd: CreateAppointmentMedicationInput = {
        mapID: mapSelected?.mapID,
        productID: mapSelected?.productID,
        appointmentID: appointment.id,
        doctorID,
        patientID: patient?.id,
        phone: appointment.phone,
        givenName: appointment.firstName,
        familyName: patient?.surName,
        dailyDose,
        quantity,
        repeats,
        repeatInterval,
      }
      const res: TbnResponse = await AppointmentMedicationService.Instance.add(cmd)
      if (!!res.data) {
        fetchItems()
        setDailyDose('')
        setQuantity('')
        setRepeats('')
        setRepeatInterval('')
      } else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
    }
    setAdding(false)
  }

  const removeFromAppointment = async (itm: AppointmentMedication) => {
    const isAllowed = await isActualDoctor()
    if (!isAllowed) {
      failure('This appointment belongs to another doctor', presentToast)
      return
    }
    setRemoving(true)
    const res: TbnResponse = await AppointmentMedicationService.Instance.remove(itm)
    if (!!res.data) fetchItems()
    else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
    setRemoving(false)
  }

  const isActualDoctor = async () => {
    if (!isDoctor(user)) {
      return false
    }
    try {
      const currentDoctor: Doctor | undefined = await CurrentDoctorService.Instance.currentDoctor()
      const doctorID = appointment.doctorID || appointment?.definition?.doctorID
      if (!!currentDoctor && currentDoctor?.id === doctorID) {
        return true
      }
    } catch (err) {
      warn(err)
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      const res: any = await isActualDoctor()
      return res
    }
    return false
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <>
      {isDoctor(user) && (
        <div className='ion-padding-bottom'>
          <IonList>
            {items.map((itm: AppointmentMedication) => (
              <IonItem key={itm.id}>
                <IonText slot='start'>
                  <ProductPresentationIcon pp={itm?.product?.presentation} />
                </IonText>
                <IonLabel className='ion-no-margin'>
                  {itm?.product?.name} ({itm?.product?.ingredients})
                  <p>
                    <IonChip>{itm?.dailyDose}</IonChip> <IonChip>n = {itm?.quantity}</IonChip>{' '}
                    <IonChip>{itm?.repeats} repeats</IonChip> <IonChip>{itm?.repeatInterval}</IonChip>
                  </p>
                </IonLabel>
                <IonText color={isBeforeToday(itm?.map?.expiryDate || '1960-00-00') ? 'danger' : ''} slot='end'>
                  {convertISOtoDateFormat1(itm?.map?.expiryDate)}
                </IonText>
                <IonButtons slot='end'>
                  <ProductCategoryBadge item={itm?.product} />
                  <IonButton
                    fill='clear'
                    color='danger'
                    title='Delete'
                    disabled={removing}
                    onClick={() =>
                      presentAlert({
                        header: `Are you sure?`,
                        subHeader: '',
                        message: `${itm?.product?.name} (${itm?.product?.ingredients})`,
                        buttons: [
                          {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {},
                          },
                          {
                            text: 'Delete',
                            role: 'destructive',
                            cssClass: 'alert-button-delete',
                            handler: () => {
                              removeFromAppointment(itm)
                            },
                          },
                        ],
                        onDidDismiss: (e: CustomEvent) => {},
                      })
                    }
                  >
                    <IonIcon icon={trashBin} slot='icon-only' />
                  </IonButton>
                </IonButtons>
              </IonItem>
            ))}
          </IonList>
          {!!patient && (
            <IonGrid className='ion-no-padding'>
              <div className='flex-container flex-space-between align-items-center'>
                <div style={{ width: '90%' }}>
                  {!hide && (
                    <MapNumberSelect
                      doctorID={appointment?.doctorID || appointment?.definition?.doctorID}
                      onChange={(itm: ProductMap | undefined) => setMapSelected(itm)}
                    />
                  )}
                </div>
                <IonButton
                  fill='clear'
                  size='small'
                  onClick={() => {
                    setHide(true)
                    setTimeout(() => {
                      setHide(false)
                    }, 1000)
                  }}
                >
                  <IonIcon icon={refresh} slot='icon-only' />
                </IonButton>
              </div>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Daily dose'
                      value={dailyDose}
                      onIonChange={(e: any) => setDailyDose(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Quantity'
                      value={quantity}
                      onIonChange={(e: any) => setQuantity(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Repeats'
                      value={repeats}
                      onIonChange={(e: any) => setRepeats(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Repeat Interval'
                      value={repeatInterval}
                      onIonChange={(e: any) => setRepeatInterval(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='12' className='ion-text-end'>
                  <IonButton
                    onClick={addToAppointment}
                    disabled={!mapSelected || adding}
                    className='ion-float-end'
                    size={isMobile() ? 'small' : 'default'}
                  >
                    <IonIcon icon={add} slot='icon-only' />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          {!patient?.id && <IonText color='danger'>Patient record is not present.</IonText>}
        </div>
      )}
      {!isDoctor(user) && <IonText color='danger'>Doctor role is required.</IonText>}
    </>
  )
}

export default AppointmentMed

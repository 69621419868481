import { FC, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { RouteComponentProps, withRouter } from 'react-router'
import { IonButton, IonButtons, IonCheckbox, IonCol, IonGrid, IonInput, IonRow, IonText } from '@ionic/react'
import { IonIcon, IonItem, IonLabel } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { calendar, card, refresh } from 'ionicons/icons'
import { ModelTypeFormEventFilterInput, ModelSortDirection, TypeFormEvent, Doctor } from '../../API'
import AppointmentTypeIcon from '../../components/models/AppointmentTypeIcon'
import DoctorSelect from '../../components/select/DoctorSelect'
import { defaultTimezone, utcToLocale } from '../../components/util/Date'
import SORT_CHAR from '../../components/util/Sorter'
import { isDesktop } from '../../components/util/isMobile'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'
import { findTypeFormEventsBySorter } from '../../graphql/queries'
import PageContainer from '../PageContainer'
import PageNavigate from '../doctor/PageNavigate'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AppointmentsProps extends OwnProps, StateProps, DispatchProps {}
const Appointments: FC<AppointmentsProps> = ({ net, setNet }) => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [present] = useIonToast()
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any>([])
  const [doctorID, setDoctorID] = useState<string | undefined>()
  const [givenName, setGivenName] = useState<string | null>()
  const [phone, setPhone] = useState<string | null>()
  const [cancelled, setCancelled] = useState<boolean>()
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length

  const fetchItems = async () => {
    setLoading(true)
    const filter: ModelTypeFormEventFilterInput = {}
    if (!!doctorID) {
      filter.doctorID = { eq: doctorID }
    }
    if (!!phone) {
      filter.phone = { contains: phone }
    }
    if (!!givenName) {
      filter.firstName = { contains: givenName }
    }
    if (!!cancelled) {
      filter.canceled = { eq: cancelled }
    }
    const variables = {
      nextToken,
      filter,
      sorter: SORT_CHAR,
      sortDirection: ModelSortDirection.DESC,
    }
    try {
      const res: any = await API.graphql({
        query: findTypeFormEventsBySorter,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      setItems(res?.data?.findTypeFormEventsBySorter?.items || [])
      setNextNextToken(res?.data?.findTypeFormEventsBySorter?.nextToken)
      setLoading(false)
    } catch (err: any) {
      if (!!doctorID) {
        present({ message: err?.errors[0]?.message, color: 'danger', duration: 3000 })
      }
      setLoading(false)
      setItems([])
    }
  }

  const next = () => {
    setPreviousTokens((prev: any) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  const reset = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(null)
  }

  // eslint-disable-next-line
  const resetFormId = (doctorid: string) => {
    reset()
    setDoctorID(doctorid)
  }

  const resolveDate = (itm: any) => {
    return !!itm.startTime ? `${utcToLocale(itm.startTime)} (${defaultTimezone()})` : 'null'
  }

  useEffect(() => {
    fetchItems()
  }, [nextToken, doctorID, phone, givenName, cancelled]) // eslint-disable-line

  useEffect(() => {
    setNet(loading)
  }, [loading]) // eslint-disable-line

  return (
    <PageContainer
      id='appointments'
      hideFooter={true}
      isPrivate={true}
      title='Appointments'
      actionButtonsEnd={
        <>
          {!!items.length && <IonText>{items.length}</IonText>}
          <IonButton onClick={fetchItems} disabled={loading}>
            <IonIcon icon={refresh} slot='icon-only' />
          </IonButton>
        </>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <DoctorSelect onChange={(e: Doctor) => setDoctorID(e.id)} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position='floating'>Given name</IonLabel>
              <IonInput value={givenName} onIonChange={(e) => setGivenName(e.detail.value)} debounce={1000} />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position='floating'>Phone</IonLabel>
              <IonInput value={phone} onIonChange={(e) => setPhone(e.detail.value)} debounce={1000} />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonCheckbox slot='start' checked={cancelled} onIonChange={(e) => setCancelled(e.detail.checked)} />
              <IonLabel>Cancelled</IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        {items.map((itm: TypeFormEvent) => (
          <IonItem key={itm.id}>
            <AppointmentTypeIcon item={itm} />
            <IonLabel>
              <span>
                {itm?.firstName}, {itm.email}{' '}
                <CopyToClipboard text={itm.phone || ''}>
                  <span>{itm.phone}</span>
                </CopyToClipboard>
              </span>
              <p>
                <IonText color={itm.canceled ? 'danger' : ''}>{resolveDate(itm)}</IonText>
                {itm.rescheduled && (
                  <IonText color='warning' className='ion-margin-horizontal'>
                    Rescheduled
                  </IonText>
                )}
                {itm.canceled && (
                  <IonText color='danger' className='ion-margin-horizontal'>
                    Canceled: {itm.cancelReason}
                  </IonText>
                )}
              </p>
            </IonLabel>
            <IonButtons slot='end'>
              {isDesktop() && itm.paymentSuccess && (
                <IonButton fill='clear' color='success' title={'A$ ' + itm.paymentAmount}>
                  <IonIcon icon={card} slot='icon-only'></IonIcon>
                </IonButton>
              )}
              {isDesktop() && (
                <IonButton
                  fill='clear'
                  onClick={() =>
                    window.open(itm?.bookingUrl?.replace('https://api.calendly.com', 'https://calendly.com'), '_blank')
                  }
                >
                  <IonIcon icon={calendar} slot='icon-only'></IonIcon>
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        ))}
        <IonRow>
          <IonCol>
            <PageNavigate {...{ hasNext, hasPrev, prev, next, loading }} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: withRouter(Appointments),
})

import { FC, useEffect, useRef, useState } from 'react'
import { IonButton, IonButtons, IonCard, IonCardContent } from '@ionic/react'
import { IonCardHeader, IonCardSubtitle, IonContent, IonDatetime, IonDatetimeButton } from '@ionic/react'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import { IonFooter, IonInput, IonItem, IonLabel, IonModal } from '@ionic/react'
import { IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { Doctor, MedicalAuthorisedPrescriber, TgaStatus } from '../../../API'
import { CurrentDoctorService } from '../../../components/services/CurrentDoctorService'
import { MedicalAuthorisedPrescriberService } from '../../../components/services/MedicalAuthorisedPrescriberService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { todayInUTC } from '../../../components/util/Date'
import { failure } from '../../../components/util/Toast'
import ProductMapList from './ProductMapList'

interface OwnProps {
  map?: MedicalAuthorisedPrescriber
  isOpen: boolean
  onDidDismiss: (event: any) => void
}
interface MapNumberModalFormProps extends OwnProps {}
const MapNumberModalForm: FC<MapNumberModalFormProps> = ({ isOpen, onDidDismiss, map }) => {
  const [today] = useState(todayInUTC())
  const [model, setModel] = useState<MedicalAuthorisedPrescriber>(
    !!map
      ? map
      : ({
          idxOrder: 1,
          status: TgaStatus.TGA_APPROVED,
          createdDate: today,
          decisionDate: today,
          expiryDate: today,
        } as MedicalAuthorisedPrescriber),
  )
  const modalRef = useRef<any>()
  const [saving, setSaving] = useState(false)
  const [presentToast] = useIonToast()

  const store = async () => {
    setSaving(true)
    if (!model?.doctorID) {
      try {
        const doctor: Doctor = await CurrentDoctorService.Instance.currentDoctor()
        model.doctorID = doctor?.id
      } catch (ignore) {}
    }
    const res: TbnResponse = await MedicalAuthorisedPrescriberService.Instance.store(model)
    if (res.data) modalRef?.current?.dismiss(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setSaving(false)
  }

  const isInvalid = () => {
    return !model?.mapNumber
  }

  useEffect(() => {
    try {
      CurrentDoctorService.Instance.currentDoctor().catch((ignore) => {})
    } catch (ignore) {}
  }, [])

  return (
    <IonModal id='map-number-modal-form' isOpen={isOpen} onDidDismiss={onDidDismiss} ref={modalRef}>
      <IonContent className='ion-padding'>
        <IonItem>
          <IonInput
            placeholder='MAPxx-xxxxxxx'
            value={model.mapNumber}
            onIonChange={(e: any) => setModel({ ...model, mapNumber: e.detail.value })}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Created Date</IonLabel>
          <IonDatetimeButton datetime='createdDate'></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonLabel className='ion-text-center'>Created Date</IonLabel>
            <IonDatetime
              value={model?.createdDate || today}
              id='createdDate'
              showDefaultButtons={true}
              presentation={'date'}
              onIonChange={(e) => setModel({ ...model, createdDate: e.detail.value as string })}
              yearValues={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Decision Date</IonLabel>
          <IonDatetimeButton datetime='decisionDate'></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonLabel className='ion-text-center'>Decision Date</IonLabel>
            <IonDatetime
              value={model?.decisionDate || today}
              id='decisionDate'
              showDefaultButtons={true}
              presentation={'date'}
              onIonChange={(e) => setModel({ ...model, decisionDate: e.detail.value as string })}
              yearValues={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Expiry Date</IonLabel>
          <IonDatetimeButton datetime='expiryDate'></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonLabel className='ion-text-center'>Expiry Date</IonLabel>
            <IonDatetime
              value={model?.expiryDate || today}
              id='expiryDate'
              showDefaultButtons={true}
              presentation={'date'}
              onIonChange={(e) => setModel({ ...model, expiryDate: e.detail.value as string })}
              yearValues={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel className='ion-margin-bottom' position='stacked'>
            Status
          </IonLabel>
          <IonSegment
            value={model.status}
            onIonChange={(e: any) => {
              setModel({ ...model, status: e.detail.value })
            }}
            mode='ios'
          >
            <IonSegmentButton value={TgaStatus.TGA_APPROVED}>TGA approved</IonSegmentButton>
            <IonSegmentButton value={undefined}>Other</IonSegmentButton>
          </IonSegment>
        </IonItem>
        {!!model?.id && (
          <IonCard>
            <IonCardHeader className='ion-no-padding'>
              <IonCardSubtitle className='ion-padding-start'>Products</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <ProductMapList mapID={model?.id} />
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => onDidDismiss(null)}>Cancel</IonButton>
            <IonButton color='primary' disabled={saving || isInvalid()} onClick={store}>
              {saving && <IonSpinner slot='start' />}
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default MapNumberModalForm

import React from 'react'
import { Patient } from '../../API'
import Booking from '../../pages/booking/Booking.model'
import { Quiz } from '../../pages/quiz/helpers/Quiz.model'
import {
  getUserData,
  setEmailSentData,
  setBookingData,
  setConsentData,
  setDarkModeData,
  setLoginModeData,
  setDoctorNoteData,
} from '../dataApi'
import { ActionType } from '../types'
import { UserState } from './user.state'

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true))
  const data: any = await getUserData()
  dispatch(setData(data))
  dispatch(setLoading(false))
}

export const setNet = (net: boolean) =>
  ({
    type: 'set-net',
    net,
  } as const)

export const setLoading = (isLoading: boolean) =>
  ({
    type: 'set-user-loading',
    isLoading,
  } as const)

export const setData = (data: Partial<UserState>) =>
  ({
    type: 'set-user-data',
    data,
  } as const)

export const setDarkMode = (darkMode: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setDarkModeData(darkMode)
  return {
    type: 'set-dark-mode',
    darkMode,
  } as const
}

export const setEmailSent = (emailSent: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setEmailSentData(emailSent)
  return {
    type: 'set-email-sent',
    emailSent,
  } as const
}

export const setLoginMode = (loginMode: 'phone_number' | 'email') => async (dispatch: React.Dispatch<any>) => {
  await setLoginModeData(loginMode)
  return {
    type: 'set-login-mode',
    loginMode,
  } as const
}

export const setConsent = (consent: object) => async (dispatch: React.Dispatch<any>) => {
  await setConsentData(consent)
  return {
    type: 'set-consent',
    consent,
  } as const
}

export const setBooking = (booking?: Booking) => async (dispatch: React.Dispatch<any>) => {
  await setBookingData(booking)
  return {
    type: 'set-booking',
    booking,
  } as const
}

export const setDoctorNote = (doctorNote?: string) => async (dispatch: React.Dispatch<any>) => {
  await setDoctorNoteData(doctorNote)
  return {
    type: 'set-doctor-note',
    doctorNote,
  } as const
}

export const setMenuEnabled = (menuEnabled: boolean) =>
  ({
    type: 'set-menu-enabled',
    menuEnabled,
  } as const)

export const setPatientDetail = (patientDetail: Patient) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-patient-detail',
    patientDetail,
  } as const
}

export const setPatientHealth = (patientHealth: Patient) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-patient-health',
    patientHealth,
  } as const
}

export const setQuiz = (quiz?: Quiz) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-quiz',
    quiz,
  } as const
}

export type UserActions =
  | ActionType<typeof setNet>
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setMenuEnabled>
  | ActionType<typeof setConsent>
  | ActionType<typeof setBooking>
  | ActionType<typeof setPatientDetail>
  | ActionType<typeof setPatientHealth>
  | ActionType<typeof setQuiz>
  | ActionType<typeof setLoginMode>
  | ActionType<typeof setEmailSent>
  | ActionType<typeof setDoctorNote>

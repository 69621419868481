import { FC, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IonButton, IonButtons, IonGrid, IonIcon } from '@ionic/react'
import { IonItem, IonList, IonText, IonTextarea, useIonAlert, useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { add, clipboard, trashBin } from 'ionicons/icons'
import { AppointmentNote, CreateAppointmentNoteInput, Doctor, Patient, TypeFormEvent } from '../../API'
import { AppointmentNoteService } from '../../components/services/AppointmentNoteService'
import { CurrentDoctorService } from '../../components/services/CurrentDoctorService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { isDoctor } from '../../components/util/Auth'
import { warn } from '../../components/util/Log'
import { failure, info } from '../../components/util/Toast'
import { isMobile } from '../../components/util/isMobile'
import { connect } from '../../data'
import { setDoctorNote } from '../../data/user/user.actions'

interface OwnProps {
  appointment: TypeFormEvent
  patient?: Patient | null
}
interface StateProps {
  doctorNote?: string
}
interface DispatchProps {
  setDoctorNote: typeof setDoctorNote
}
interface AppointmentNoteProps extends OwnProps, StateProps, DispatchProps {}
const AppointmentNoteComp: FC<AppointmentNoteProps> = ({ appointment, patient, doctorNote, setDoctorNote }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [items, setItems] = useState([])
  const [presentToast] = useIonToast()
  const [presentAlert] = useIonAlert()
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)

  const fetchItems = async () => {
    const res: TbnResponse = await AppointmentNoteService.Instance.findByAppointmentId(appointment.id)
    if (!!res.data) setItems(res.data)
    else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
  }

  const addToAppointment = async () => {
    const isAllowed = await isActualDoctor()
    if (!isAllowed) {
      failure('This appointment belongs to another doctor', presentToast)
      return
    }
    setAdding(true)
    const doctorID = appointment.doctorID || appointment?.definition?.doctorID
    if (!!doctorNote && !!doctorID && !!patient?.id) {
      const cmd: CreateAppointmentNoteInput = {
        note: doctorNote,
        appointmentID: appointment.id,
        doctorID,
        patientID: patient?.id,
        phone: appointment.phone,
        givenName: appointment.firstName,
        familyName: patient?.surName,
      }
      const res: TbnResponse = await AppointmentNoteService.Instance.add(cmd)
      if (!!res.data) {
        await fetchItems()
        setDoctorNote('')
      } else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
    }
    setAdding(false)
  }

  const removeFromAppointment = async (itm: AppointmentNote) => {
    const isAllowed = await isActualDoctor()
    if (!isAllowed) {
      failure('This appointment belongs to another doctor', presentToast)
      return
    }
    setRemoving(true)
    const res: TbnResponse = await AppointmentNoteService.Instance.remove(itm)
    if (!!res.data) fetchItems()
    else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
    setRemoving(false)
  }

  const isActualDoctor = async () => {
    if (!isDoctor(user)) {
      return false
    }
    try {
      const currentDoctor: Doctor | undefined = await CurrentDoctorService.Instance.currentDoctor()
      const doctorID = appointment.doctorID || appointment?.definition?.doctorID
      if (!!currentDoctor && currentDoctor?.id === doctorID) {
        return true
      }
    } catch (err) {
      warn(err)
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      const res: any = await isActualDoctor()
      return res
    }
    return false
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <>
      {isDoctor(user) && (
        <div className='ion-padding-bottom'>
          <IonList>
            {items.map((itm: AppointmentNote) => (
              <IonItem key={itm.id}>
                <IonTextarea value={itm.note} readonly={true} autoGrow={true}></IonTextarea>
                <IonButtons slot='end'>
                  <CopyToClipboard text={itm.note} onCopy={() => info('Copied', presentToast)}>
                    <IonButton fill='clear' color={'medium'}>
                      <IonIcon icon={clipboard} slot='icon-only' />
                    </IonButton>
                  </CopyToClipboard>
                  <IonButton
                    fill='clear'
                    color='danger'
                    title='Delete'
                    disabled={removing}
                    onClick={() =>
                      presentAlert({
                        header: `Are you sure?`,
                        subHeader: '',
                        message: `${itm?.note?.substring(0, 50)}...`,
                        buttons: [
                          {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {},
                          },
                          {
                            text: 'Delete',
                            role: 'destructive',
                            cssClass: 'alert-button-delete',
                            handler: () => {
                              removeFromAppointment(itm)
                            },
                          },
                        ],
                        onDidDismiss: (e: CustomEvent) => {},
                      })
                    }
                  >
                    <IonIcon icon={trashBin} slot='icon-only' />
                  </IonButton>
                </IonButtons>
              </IonItem>
            ))}
          </IonList>
          {!!patient && (
            <IonGrid className='ion-no-padding'>
              <IonItem>
                <IonTextarea
                  autoGrow={true}
                  value={doctorNote}
                  onIonChange={(e: any) => setDoctorNote(e.detail.value)}
                  placeholder={'Add note...'}
                />
                <IonButton
                  slot='end'
                  onClick={addToAppointment}
                  disabled={!doctorNote || adding}
                  className='ion-float-end'
                  size={isMobile() ? 'small' : 'default'}
                >
                  <IonIcon icon={add} slot='icon-only' />
                </IonButton>
              </IonItem>
            </IonGrid>
          )}
          {!patient?.id && <IonText color='danger'>Patient record is not present.</IonText>}
        </div>
      )}
      {!isDoctor(user) && <IonText color='danger'>Doctor role is required.</IonText>}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    doctorNote: state.user.doctorNote,
  }),
  mapDispatchToProps: {
    setDoctorNote,
  },
  component: AppointmentNoteComp,
})

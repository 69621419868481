import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonButton, IonCol, IonDatetime, IonDatetimeButton, IonGrid, IonIcon, IonLabel, IonModal } from '@ionic/react'
import { IonRow, IonText, useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { cloudDownload, refresh } from 'ionicons/icons'
import { Clinic, ModelSortDirection, ModelTypeFormEventFilterInput, TypeFormEvent } from '../../API'
import appointmentSubjectLabel from '../../components/models/AppointmentSubjectLabel'
import { CurrentAccountantService } from '../../components/services/CurrentAccountantService'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'
import { findTypeFormEventsByClinicId } from '../../graphql-custom/accountant/queries'
import AccountantContainer from './AccountantContainer'
import AppointmentStat from './AppointmentStat'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AccountantDeskProps extends OwnProps, StateProps, DispatchProps {}
const AccountantDesk: FC<AccountantDeskProps> = ({ setNet, net }) => {
  const [items, setItems] = useState([])
  const [nextNextToken, setNextNextToken] = useState(undefined)
  const [clinic, setClinic] = useState<Clinic>()
  const [presentToast] = useIonToast()
  const [startTimeFrom, setStartTimeFrom] = useState<string | null>('2022-11-01T00:00:00-00:00')
  const [startTimeTo, setStartTimeTo] = useState<string | null>('2022-12-01T00:00:00-00:00')
  const [downloading, setDownloading] = useState(false)

  const fetchItems = async () => {
    setNet(true)
    if (!!startTimeFrom && !!startTimeTo) {
      const filter: ModelTypeFormEventFilterInput = {
        canceled: { ne: true },
      }
      const variables = {
        clinicID: clinic?.id,
        filter,
        startTime: { between: [startTimeFrom, startTimeTo] },
        sortDirection: ModelSortDirection.ASC,
      }
      try {
        const res: any = await API.graphql({
          query: findTypeFormEventsByClinicId,
          variables,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        setItems(res?.data?.findTypeFormEventsByClinicId?.items || [])
        setNextNextToken(res?.data?.findTypeFormEventsByClinicId?.nextToken)
      } catch (ignore) {}
    }
    setNet(false)
  }

  const resolveAccountantClinic = async (): Promise<Clinic> => {
    try {
      const reception = await CurrentAccountantService.Instance.currentClinic()
      return reception
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveAccountantClinic()
    }
  }

  const resolveClinic = async () => {
    setNet(true)
    const clinic: Clinic = await resolveAccountantClinic()
    if (!!clinic?.id) setClinic(clinic)
    else failure('Failed to load clinic', presentToast)
    setNet(false)
  }

  const downloadCSV = () => {
    setDownloading(true)
    const rows: any[] = []
    rows.push(['Doctor name', 'Appointment type', 'Appointment date'])
    for (let itm of items) {
      const row = itm as TypeFormEvent
      rows.push([row?.doctor?.drName, appointmentSubjectLabel(row?.definitionSubject), row?.startTime])
    }
    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e: any) => e.join(',')).join('\n')
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${clinic?.publicId}-${startTimeFrom?.substring(0, 10)}_${startTimeTo?.substring(0, 10)}.csv`,
    )
    document.body.appendChild(link) // Required for FF
    link.click()
    setDownloading(false)
  }

  useEffect(() => {
    if (!!clinic?.id) fetchItems()
  }, [clinic?.id, startTimeFrom, startTimeTo]) // eslint-disable-line

  useEffect(() => {
    resolveClinic()
  }, []) // eslint-disable-line

  return (
    <AccountantContainer
      id='accountant-desk'
      title={'Accountant Desk'}
      actionButtonsEnd={
        <IonButton
          onClick={() => {
            if (!!clinic?.id) {
              fetchItems()
            }
          }}
          disabled={net}
        >
          <IonIcon icon={refresh} slot='icon-only' />
        </IonButton>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol size='1' className='ion-text-end'>
            From
          </IonCol>
          <IonCol>
            <IonDatetimeButton datetime='startTimeFrom'></IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonLabel className='ion-text-center'>From (UTC timezone)</IonLabel>
              <IonDatetime
                value={startTimeFrom}
                id='startTimeFrom'
                showDefaultButtons={true}
                onIonChange={(e) => setStartTimeFrom(e.detail.value as string)}
              ></IonDatetime>
            </IonModal>
          </IonCol>
          <IonCol size='1' className='ion-text-end'>
            To
          </IonCol>
          <IonCol>
            <IonDatetimeButton datetime='startTimeTo'></IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonLabel className='ion-text-center'>To (UTC timezone)</IonLabel>
              <IonDatetime
                value={startTimeTo}
                id='startTimeTo'
                showDefaultButtons={true}
                onIonChange={(e) => setStartTimeTo(e.detail.value as string)}
              ></IonDatetime>
            </IonModal>
          </IonCol>
          {items?.length > 0 && (
            <IonCol size='1'>
              <IonButton fill='clear' title='CSV download' onClick={downloadCSV} disabled={downloading}>
                <IonIcon icon={cloudDownload} slot='icon-only' />
              </IonButton>
            </IonCol>
          )}
        </IonRow>
        {!!nextNextToken && (
          <IonText color='danger'>
            Number of appointments exceed the limit, please deacrease duration between dates.
          </IonText>
        )}
        <div className='ion-margin-top'></div>
        <AppointmentStat appts={items} />
      </IonGrid>
    </AccountantContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: AccountantDesk,
})

import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Doctor, ModelDoctorFilterInput, UpdateDoctorInput } from '../../API'
import { findDoctorByDrId } from '../../graphql-custom/public/queries'
import { createDoctor, updateDoctor } from '../../graphql/mutations'
import { findDoctorByUserId, listDoctors } from '../../graphql/queries'
import { AuthStatus } from '../util/AuthStatus'
import { log, warn } from '../util/Log'
import hasNewChanges from '../util/hasNewChanges'
import { TbnResponse } from './TbnResponse'

export class DoctorService {
  private static doctorService: DoctorService | undefined

  public async list(filter?: ModelDoctorFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listDoctors,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return Promise.resolve({ data: res?.data?.listDoctors?.items, errorMessage: res?.errors?.[0]?.message })
    } catch (err) {
      warn('error fetching doctor: ', err)
      return Promise.reject(err)
    }
  }

  public async findDoctor(userID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findDoctorByUserId,
        variables: { userID },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForDoctorDuplication(res?.data?.findDoctorByUserId?.items)
      return { data: res?.data?.findDoctorByUserId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error fetching doctor: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async addOrEditDoctor(userID: string, doctor: Doctor): Promise<TbnResponse> {
    try {
      const res: TbnResponse = await this.findDoctor(userID)
      if (!!res.data) {
        return { data: await this.editDoctor(res.data, doctor) }
      } else {
        return { data: await this.addDoctor(userID, doctor) }
      }
    } catch (err) {
      log('error fetching doctor: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async editDoctor(previous: Doctor, doctor: Doctor) {
    const next: UpdateDoctorInput = {
      id: previous.id,
      drId: doctor.drId?.toLowerCase(),
      drName: doctor.drName,
      avatar: doctor.avatar,
      qualifications: doctor.qualifications,
      prescriberNumber: doctor.prescriberNumber,
    }
    log('Doctor update command', next)
    if (this.includesNoNewChanges(next, previous)) {
      log('Doctor update skipped, no new changes')
      return previous
    } else {
      const res: any = await API.graphql({
        query: updateDoctor,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('Doctor updated to', res?.data?.updateDoctor)
      return res?.data?.updateDoctor
    }
  }

  public async addDoctor(userID: string, doctor: Doctor) {
    try {
      const res: any = await API.graphql({
        query: createDoctor,
        variables: {
          input: {
            userID,
            drId: doctor.drId?.toLowerCase(),
            drName: doctor.drName,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create doctor res', res)
      return res.data.createDoctor
    } catch (err) {
      log('Error creating doctor! :', err)
      return err
    }
  }

  private checkForDoctorDuplication(items?: any[]) {
    if (!!items && items.length > 1) {
      warn('FOUND DUPLICATED DOCTOR!!!', items)
    }
  }

  private includesNoNewChanges(cmd: UpdateDoctorInput, previous: Doctor) {
    return hasNewChanges(cmd, previous)
  }

  public async publicFind(drId: string, authStatus: AuthStatus): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findDoctorByDrId,
        variables: { drId: drId?.toLowerCase() },
        authMode:
          authStatus !== AuthStatus.AUTHENTICATED
            ? GRAPHQL_AUTH_MODE.AWS_IAM
            : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findDoctorByDrId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching: ', drId, authStatus, err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  private async buildDoctorService(): Promise<DoctorService> {
    try {
      let service = new DoctorService()
      return Promise.resolve(service)
    } catch (err) {
      warn('Init DoctorService failure:', err)
      return this.buildDoctorService()
    }
  }

  public static get Instance(): DoctorService {
    if (!this.doctorService) {
      this.doctorService = new DoctorService()
      this.doctorService.buildDoctorService().then((service: DoctorService) => {
        this.doctorService = service
      })
    }
    return this.doctorService
  }
}

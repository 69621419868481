import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonText, useIonToast } from '@ionic/react'
import { document, documentOutline } from 'ionicons/icons'
import { Quiz, QuizType } from '../../API'
import Spinner from '../../components/helpers/Spinner'
import { QuizService } from '../../components/services/QuizService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { UserService } from '../../components/services/UserService'
import { utcToLocaleDate } from '../../components/util/Date'
import { error } from '../../components/util/Log'
import QuizModal from '../doctor/QuizModal'
import ChronicPainAnswerModal from '../quiz/chronic-pain/ChronicPainAnswerModal'
import Dass21AnswerModal from '../quiz/dass-21/Dass21AnswerModal'

interface OwnProps {
  doubleCheck: boolean
  givenName: string
  email?: string | null
  phone?: string | null
}
interface PatientQuizViewProps extends OwnProps {}
const PatientQuizView: FC<PatientQuizViewProps> = ({ email, phone, doubleCheck, givenName }) => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [quiz, setQuiz] = useState<Quiz>()
  const [present] = useIonToast()
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)

  const findItems = async () => {
    setLoading(true)
    try {
      const user: TbnResponse | undefined = await UserService.Instance?.findByPhoneOrEmail(phone, email)
      const res: any = await QuizService.Instance.findQuizzes(user?.data?.id, doubleCheck ? givenName : undefined)
      setItems(res)
      setLoading(false)
    } catch (err: any) {
      error(err)
      present({ message: `Error: ${err?.errors?.[0]?.message}`, color: 'danger', duration: 3000 })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!!email || !!phone) {
      findItems()
    }
  }, [email, phone, doubleCheck]) // eslint-disable-line

  return (
    <>
      {!email && !phone && <IonText color='danger'>Phone or Email is required.</IonText>}
      {loading && <Spinner />}
      {!loading &&
        items.map((qz: Quiz) => (
          <IonItem key={qz.id}>
            <IonLabel>
              {qz.quizType?.split('_').join(' ')}
              <p>{utcToLocaleDate(qz?.createdAt)}</p>
            </IonLabel>
            <IonButtons slot='end'>
              {qz?.quizType === QuizType.CHRONIC_PAIN && (
                <IonButton
                  color='primary'
                  onClick={() => {
                    setShowModal2(false)
                    setQuiz(undefined)
                    setTimeout(() => {
                      setQuiz(qz)
                      setShowModal2(true)
                    }, 0)
                  }}
                >
                  <IonIcon icon={document} slot='icon-only' />
                </IonButton>
              )}
              <IonButton
                onClick={() => {
                  setShowModal(false)
                  setShowModal3(false)
                  setQuiz(undefined)
                  setTimeout(() => {
                    setQuiz(qz)
                    if (qz?.quizType === QuizType.CHRONIC_PAIN) {
                      setShowModal(true)
                    } else if (qz?.quizType === QuizType.DASS_21) {
                      setShowModal3(true)
                    }
                  }, 0)
                }}
              >
                <IonIcon icon={documentOutline} slot='icon-only' />
              </IonButton>
            </IonButtons>
          </IonItem>
        ))}
      {!loading && items.length === 0 && (
        <div className='ion-padding ion-margin ion-text-start'>
          <IonText>No record</IonText>
        </div>
      )}
      {!!quiz && <QuizModal model={quiz} showModal={showModal} setShowModal={setShowModal} />}
      {!!quiz && <ChronicPainAnswerModal model={quiz} showModal={showModal2} setShowModal={setShowModal2} />}
      {!!quiz && (
        <Dass21AnswerModal
          model={quiz}
          showModal={showModal3}
          setShowModal={setShowModal3}
          showNumbersDefault={true}
          doctorRole={true}
        />
      )}
    </>
  )
}

export default PatientQuizView

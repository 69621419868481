import { useState, useEffect } from 'react'
import { IonSpinner } from '@ionic/react'
import { Auth, Analytics, API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Widget } from '@typeform/embed-react'
import { findTypeFormEventsByEmail } from '../../graphql-custom/queries'
import PageContainer from '../PageContainer'
import './ScreeningLegacy.scss'

const SCREENING_ID = process?.env?.REACT_APP_TYPEFORM_SCREENING_ID
const QUICK_EXAMINATION_ID = process?.env?.REACT_APP_TYPEFORM_QUICK_EXAMINATION_ID
const INITIAL_CONSULTATION_ID = process?.env?.REACT_APP_TYPEFORM_INITIAL_CONSULTATION_ID
const NORMAL_CONSULTATION_ID = process?.env?.REACT_APP_TYPEFORM_NORMAL_CONSULTATION_ID

const ScreeningLegacy = () => {
  const [user, setUser] = useState<any | undefined>()
  const [inprogress, setInprogress] = useState(true)
  const [items, setItems] = useState([])
  const [questions, setQuestions] = useState<any>([])
  const [answers, setAnswers] = useState<any>([])

  const lookupFormId = async () => {
    setInprogress(true)
    try {
      const userInfo: any = await Auth.currentAuthenticatedUser()
      setUser(userInfo)
      const res: any = await API.graphql({
        query: findTypeFormEventsByEmail,
        variables: { email: userInfo?.attributes?.email },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      setItems(res?.data?.findTypeFormEventsByEmail?.items || [])
      setTimeout(() => {
        setInprogress(false)
      }, 300)
      Analytics.record({ name: 'Screening (signed in)' })
    } catch (err) {
      setInprogress(false)
      Analytics.record({ name: 'Screening (annoymous)' })
    }
  }

  const formId = () => {
    if (user?.attributes?.email) {
      const doneScreening: boolean = items.some((f: any) => f?.formId === SCREENING_ID)
      const doneQuickExamination: boolean = items.some((f: any) => f?.formId === QUICK_EXAMINATION_ID)
      const doneInitialConsultation: boolean = items.some((f: any) => f?.formId === INITIAL_CONSULTATION_ID)
      if (doneInitialConsultation) {
        return NORMAL_CONSULTATION_ID
      } else if (doneScreening || doneQuickExamination) {
        return INITIAL_CONSULTATION_ID
      } else {
        return QUICK_EXAMINATION_ID
      }
    } else {
      return SCREENING_ID
    }
  }

  const resolveFirstName = (): string => {
    const firstNameQuestionRef = questions.filter((q: any) => q.title === 'What is your first name?')
    if (!!firstNameQuestionRef && firstNameQuestionRef.length > 0) {
      return answers.filter((a: any) => a?.field?.ref === firstNameQuestionRef[0]?.ref)[0]?.text
    }
    return ''
  }

  useEffect(() => {
    lookupFormId()
  }, []) // eslint-disable-line

  useEffect(() => {
    setQuestions(items.flatMap((i: any) => JSON.parse(i.questions)) || [])
    setAnswers(items.flatMap((i: any) => JSON.parse(i.answers)) || [])
  }, [items])

  return (
    <PageContainer id='screening' isPrivate={false} hideMenu={true} hideHeader={true} padding={false} hideFooter={true}>
      {!inprogress && (
        <Widget
          id={formId() || 'not found'}
          style={{ width: '100%', height: '100vh' }}
          hidden={{
            email: user?.attributes?.email || '',
            firstname: user?.attributes?.given_name || resolveFirstName() || '',
          }}
        />
      )}
      {inprogress && (
        <div className='ion-text-center'>
          <IonSpinner />
        </div>
      )}
    </PageContainer>
  )
}

export default ScreeningLegacy

import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import { IonCardHeader, useIonViewWillLeave } from '@ionic/react'
import { IonButton, IonCard, IonCardContent } from '@ionic/react'
import { IonCardTitle, IonCol, IonGrid, IonInput, IonItem, IonList } from '@ionic/react'
import { IonLabel, IonRadio, IonRadioGroup, IonRow, IonSpinner, IonText, IonTextarea, useIonToast } from '@ionic/react'
import { Clinic, ClinicStatus, Location } from '../../../API'
import { ClinicService } from '../../../components/services/ClinicService'
import ImageUpload from '../../../components/util/ImageUpload'
import TelSpinner from '../../../components/util/TelSpinner'
import { failure, success } from '../../../components/util/Toast'
import AdminContainer from '../AdminContainer'
import ClinicAccountantList from './ClinicAccountantList'
import ClinicAdminList from './ClinicAdminList'
import ClinicDoctorList from './ClinicDoctorList'
import ClinicReceptionList from './ClinicReceptionList'

interface OwnProps extends RouteComponentProps {}
interface ClinicEditProps extends OwnProps {}
const ClinicEdit: FC<ClinicEditProps> = ({ history }) => {
  const { clinicId } = useParams<any>()
  const [clinic, setClinic] = useState<Clinic>({} as Clinic)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [present] = useIonToast()

  const store = async () => {
    setSaving(true)
    const res: any = await ClinicService.Instance.store(clinic)
    if (!!clinic.id && res?.data?.updateClinic) {
      setClinic(res.data.updateClinic)
      success('Success updating clinic', present)
    } else if (res?.data?.createClinic) {
      setClinic(res.data.createClinic)
      success('Success creating clinic', present)
    } else {
      failure(res?.errors?.[0]?.message, present)
    }
    setSaving(false)
  }

  const loadItem = async (itemId: string) => {
    setLoading(true)
    const res: any = await ClinicService.Instance.load(itemId)
    setClinic(res)
    setLoading(false)
  }

  const isClinicNew = () => !clinic?.id

  const isFormInvalid = () => !clinic?.name || !/^(\w|\.|-)+$/.test(clinic?.publicId)

  useIonViewWillLeave(() => {
    setClinic({} as Clinic)
  })

  useEffect(() => {
    if (clinicId !== 'new') {
      loadItem(clinicId)
    } else {
      setLoading(true)
      setClinic({} as Clinic)
      setTimeout(() => {
        setLoading(false)
      }, 300)
    }
  }, [clinicId]) // eslint-disable-line

  return (
    <AdminContainer id='clinic-edit' title={isClinicNew() && !loading ? 'New Clinic' : clinic?.name}>
      {!loading && (
        <IonGrid>
          <IonItem>
            <IonLabel position='floating' className='required'>
              Public Id{' '}
              {!/^(\w|\.|-)+$/.test(clinic?.publicId) && (
                <IonText className='ion-margin-start' color='danger'>
                  Invalid character
                </IonText>
              )}
            </IonLabel>
            <IonInput
              value={clinic?.publicId}
              onIonChange={(e) => setClinic({ ...clinic, publicId: e.detail.value?.toLowerCase() } as Clinic)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='required'>
              Name
            </IonLabel>
            <IonInput
              value={clinic?.name}
              onIonChange={(e) => setClinic({ ...clinic, name: e.detail.value } as Clinic)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Formerly known as</IonLabel>
            <IonInput
              value={clinic?.formerlyKnownAs}
              onIonChange={(e) => setClinic({ ...clinic, formerlyKnownAs: e.detail.value } as Clinic)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Phone</IonLabel>
            <IonInput
              value={clinic?.phone}
              onIonChange={(e) => setClinic({ ...clinic, phone: e.detail.value })}
              placeholder={'+61'}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Address</IonLabel>
            <IonTextarea
              autoGrow={true}
              value={clinic?.address}
              onIonChange={(e) => setClinic({ ...clinic, address: e.detail.value } as Clinic)}
            />
          </IonItem>
          <ImageUpload
            defaultPath='images/clinic/logo'
            defaultImage={clinic?.logoImage}
            onTbnChange={(logoImage: string) => setClinic({ ...clinic, logoImage })}
            label='Logo'
          />
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Latitude</IonLabel>
                <IonInput
                  value={clinic?.location?.lat}
                  onIonChange={(e) =>
                    setClinic({
                      ...clinic,
                      location: {
                        lat: e.detail.value || '',
                        lon: clinic?.location?.lon || '',
                      } as Location,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Longitude</IonLabel>
                <IonInput
                  value={clinic?.location?.lon}
                  onIonChange={(e) =>
                    setClinic({
                      ...clinic,
                      location: {
                        lon: e.detail.value || '',
                        lat: clinic?.location?.lat || '',
                      } as Location,
                    })
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRadioGroup value={clinic?.status} onIonChange={(e) => setClinic({ ...clinic, status: e.detail.value })}>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Active</IonLabel>
                  <IonRadio color='success' value={ClinicStatus.ACTIVE}></IonRadio>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Inactive</IonLabel>
                  <IonRadio color='warning' value={ClinicStatus.INACTIVE}></IonRadio>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Archived</IonLabel>
                  <IonRadio color='medium' value={ClinicStatus.ARCHIVED}></IonRadio>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Deleted</IonLabel>
                  <IonRadio color='danger' value={ClinicStatus.DELETED}></IonRadio>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader style={{ paddingBottom: 0 }}>
                    <IonCardTitle>Bank account details</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className='ion-no-padding'>
                    <IonList>
                      <IonItem>
                        <IonLabel position='floating'>Bank name</IonLabel>
                        <IonInput
                          value={clinic?.bankName}
                          onIonChange={(e) => setClinic({ ...clinic, bankName: e.detail.value } as Clinic)}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position='floating'>Name on account</IonLabel>
                        <IonInput
                          value={clinic?.bankAccountName}
                          onIonChange={(e) => setClinic({ ...clinic, bankAccountName: e.detail.value } as Clinic)}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position='floating'>BSB</IonLabel>
                        <IonInput
                          value={clinic?.bankStateBranch}
                          onIonChange={(e) => setClinic({ ...clinic, bankStateBranch: e.detail.value } as Clinic)}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position='floating'>Account number</IonLabel>
                        <IonInput
                          value={clinic?.bankAccountNumber}
                          onIonChange={(e) => setClinic({ ...clinic, bankAccountNumber: e.detail.value } as Clinic)}
                        />
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonRadioGroup>
          <IonRow>
            <IonCol size='12' className='ion-text-end'>
              <IonButton onClick={store} disabled={saving || isFormInvalid()}>
                {saving && <IonSpinner slot='start' />}
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {loading && <TelSpinner />}
      {!isClinicNew() && !loading && <ClinicAdminList clinicID={clinic?.id} />}
      {!isClinicNew() && !loading && <ClinicAccountantList clinicID={clinic?.id} />}
      {!isClinicNew() && !loading && <ClinicDoctorList clinicID={clinic?.id} history={history} />}
      {!isClinicNew() && !loading && <ClinicReceptionList clinicID={clinic?.id} />}
    </AdminContainer>
  )
}

export default ClinicEdit

export const findTypeFormEventsBySorter = /* GraphQL */ `
  query FindTypeFormEventsBySorter(
    $sorter: String!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsBySorter(
      sorter: $sorter
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definitionID
        definition {
          calendlyAppointmentID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        startTime
        endTime
      }
      nextToken
    }
  }
`

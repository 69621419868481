/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:b9b67652-75e2-4b11-8aa1-7ca3ddae9c9b",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Nzf5jg9nm",
    "aws_user_pools_web_client_id": "13717kcgrvanl2qfk0i04eerkp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_mobile_analytics_app_id": "fb989129bd7f408e8e73361af0c8981b",
    "aws_mobile_analytics_app_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://jhlwynx5wbbjbjt6wrsiy4qzfq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "CalendlyWebooksV1",
            "endpoint": "https://sgzc1b90of.execute-api.ap-southeast-2.amazonaws.com/stage",
            "region": "ap-southeast-2"
        },
        {
            "name": "CalendlyApiV1",
            "endpoint": "https://jd4ihotdc0.execute-api.ap-southeast-2.amazonaws.com/stage",
            "region": "ap-southeast-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://axnra84bzd.execute-api.ap-southeast-2.amazonaws.com/stage",
            "region": "ap-southeast-2"
        },
        {
            "name": "SendEmail",
            "endpoint": "https://xlh68tkgbf.execute-api.ap-southeast-2.amazonaws.com/stage",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "tbnweb60418-stage",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;

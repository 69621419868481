/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteTypeFormEvent = /* GraphQL */ `
  mutation DeleteTypeFormEvent(
    $input: DeleteTypeFormEventInput!
    $condition: ModelTypeFormEventConditionInput
  ) {
    deleteTypeFormEvent(input: $input, condition: $condition) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      definitionID
      definition {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      bookerID
      booker {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      definitionTitle
      definitionSlug
      definitionType
      definitionSubject
      definitionDoctorName
      definitionClinicName
      who
      email
      phone
      firstName
      bookingUrl
      oldBookingUrl
      paymentSuccess
      paymentAmount
      questions
      answers
      hidden
      calendlyStatus
      rescheduled
      canceled
      cancelReason
      landedAt
      submittedAt
      startTime
      endTime
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const updateConsentEvent = /* GraphQL */ `
  mutation UpdateConsentEvent(
    $input: UpdateConsentEventInput!
    $condition: ModelConsentEventConditionInput
  ) {
    updateConsentEvent(input: $input, condition: $condition) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      givenName
      familyName
      consenter
      consentContent
      researchAllowed
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteConsentEvent = /* GraphQL */ `
  mutation DeleteConsentEvent(
    $input: DeleteConsentEventInput!
    $condition: ModelConsentEventConditionInput
  ) {
    deleteConsentEvent(input: $input, condition: $condition) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      givenName
      familyName
      consenter
      consentContent
      researchAllowed
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz(
    $input: UpdateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    updateQuiz(input: $input, condition: $condition) {
      id
      userID
      quizType
      questions
      givenName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteQuiz = /* GraphQL */ `
  mutation DeleteQuiz(
    $input: DeleteQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    deleteQuiz(input: $input, condition: $condition) {
      id
      userID
      quizType
      questions
      givenName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateAppointmentNote = /* GraphQL */ `
  mutation UpdateAppointmentNote(
    $input: UpdateAppointmentNoteInput!
    $condition: ModelAppointmentNoteConditionInput
  ) {
    updateAppointmentNote(input: $input, condition: $condition) {
      id
      note
      appointmentID
      appointment {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        definitionID
        definition {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        booker {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateAppointmentMedication = /* GraphQL */ `
  mutation UpdateAppointmentMedication(
    $input: UpdateAppointmentMedicationInput!
    $condition: ModelAppointmentMedicationConditionInput
  ) {
    updateAppointmentMedication(input: $input, condition: $condition) {
      id
      mapID
      map {
        id
        mapNumber
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        status
        createdDate
        decisionDate
        expiryDate
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      appointmentID
      appointment {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        definitionID
        definition {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        booker {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      dailyDose
      quantity
      repeats
      repeatInterval
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createTypeFormEvent = /* GraphQL */ `
  mutation CreateTypeFormEvent(
    $input: CreateTypeFormEventInput!
    $condition: ModelTypeFormEventConditionInput
  ) {
    createTypeFormEvent(input: $input, condition: $condition) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      definitionID
      definition {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      bookerID
      booker {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      definitionTitle
      definitionSlug
      definitionType
      definitionSubject
      definitionDoctorName
      definitionClinicName
      who
      email
      phone
      firstName
      bookingUrl
      oldBookingUrl
      paymentSuccess
      paymentAmount
      questions
      answers
      hidden
      calendlyStatus
      rescheduled
      canceled
      cancelReason
      landedAt
      submittedAt
      startTime
      endTime
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const updateTypeFormEvent = /* GraphQL */ `
  mutation UpdateTypeFormEvent(
    $input: UpdateTypeFormEventInput!
    $condition: ModelTypeFormEventConditionInput
  ) {
    updateTypeFormEvent(input: $input, condition: $condition) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      definitionID
      definition {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      bookerID
      booker {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      definitionTitle
      definitionSlug
      definitionType
      definitionSubject
      definitionDoctorName
      definitionClinicName
      who
      email
      phone
      firstName
      bookingUrl
      oldBookingUrl
      paymentSuccess
      paymentAmount
      questions
      answers
      hidden
      calendlyStatus
      rescheduled
      canceled
      cancelReason
      landedAt
      submittedAt
      startTime
      endTime
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      sub
      username
      email
      phoneNumber
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      sub
      username
      email
      phoneNumber
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      sub
      username
      email
      phoneNumber
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      givenName
      surName
      medicareNumber
      addressOneLine
      dateOfBirth
      contactNumber
      nextOfKin
      nextOfKinRelation
      nextOfKinContact
      powerOfAttorney
      powerOfAttorneyRelation
      powerOfAttorneyContact
      carer
      carerRelation
      carerContact
      emergency
      emergencyRelation
      emergencyContact
      primaryDiagnosis
      otherTreatments
      otherMedications
      selfMedicated
      kidneyLiver
      cardiovascular
      substanceAbuseDisorder
      allergies
      smoke
      drinkAlcohol
      otherConditions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      givenName
      surName
      medicareNumber
      addressOneLine
      dateOfBirth
      contactNumber
      nextOfKin
      nextOfKinRelation
      nextOfKinContact
      powerOfAttorney
      powerOfAttorneyRelation
      powerOfAttorneyContact
      carer
      carerRelation
      carerContact
      emergency
      emergencyRelation
      emergencyContact
      primaryDiagnosis
      otherTreatments
      otherMedications
      selfMedicated
      kidneyLiver
      cardiovascular
      substanceAbuseDisorder
      allergies
      smoke
      drinkAlcohol
      otherConditions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      givenName
      surName
      medicareNumber
      addressOneLine
      dateOfBirth
      contactNumber
      nextOfKin
      nextOfKinRelation
      nextOfKinContact
      powerOfAttorney
      powerOfAttorneyRelation
      powerOfAttorneyContact
      carer
      carerRelation
      carerContact
      emergency
      emergencyRelation
      emergencyContact
      primaryDiagnosis
      otherTreatments
      otherMedications
      selfMedicated
      kidneyLiver
      cardiovascular
      substanceAbuseDisorder
      allergies
      smoke
      drinkAlcohol
      otherConditions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createConsentEvent = /* GraphQL */ `
  mutation CreateConsentEvent(
    $input: CreateConsentEventInput!
    $condition: ModelConsentEventConditionInput
  ) {
    createConsentEvent(input: $input, condition: $condition) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      givenName
      familyName
      consenter
      consentContent
      researchAllowed
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz(
    $input: CreateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    createQuiz(input: $input, condition: $condition) {
      id
      userID
      quizType
      questions
      givenName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createCalendlyAppointment = /* GraphQL */ `
  mutation CreateCalendlyAppointment(
    $input: CreateCalendlyAppointmentInput!
    $condition: ModelCalendlyAppointmentConditionInput
  ) {
    createCalendlyAppointment(input: $input, condition: $condition) {
      id
      slug
      name
      uri
      scheduling_url
      duration
      kind
      type
      color
      active
      booking_method
      description_plain
      description_html
      internal_note
      pooling_type
      secret
      profile
      custom_questions
      created_at
      updated_at
      deleted_at
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateCalendlyAppointment = /* GraphQL */ `
  mutation UpdateCalendlyAppointment(
    $input: UpdateCalendlyAppointmentInput!
    $condition: ModelCalendlyAppointmentConditionInput
  ) {
    updateCalendlyAppointment(input: $input, condition: $condition) {
      id
      slug
      name
      uri
      scheduling_url
      duration
      kind
      type
      color
      active
      booking_method
      description_plain
      description_html
      internal_note
      pooling_type
      secret
      profile
      custom_questions
      created_at
      updated_at
      deleted_at
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteCalendlyAppointment = /* GraphQL */ `
  mutation DeleteCalendlyAppointment(
    $input: DeleteCalendlyAppointmentInput!
    $condition: ModelCalendlyAppointmentConditionInput
  ) {
    deleteCalendlyAppointment(input: $input, condition: $condition) {
      id
      slug
      name
      uri
      scheduling_url
      duration
      kind
      type
      color
      active
      booking_method
      description_plain
      description_html
      internal_note
      pooling_type
      secret
      profile
      custom_questions
      created_at
      updated_at
      deleted_at
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      drId
      drName
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      avatar
      qualifications
      prescriberNumber
      clinics {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      appointmentDefinitions {
        items {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      drId
      drName
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      avatar
      qualifications
      prescriberNumber
      clinics {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      appointmentDefinitions {
        items {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      id
      drId
      drName
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      avatar
      qualifications
      prescriberNumber
      clinics {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      appointmentDefinitions {
        items {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createAppointmentDefinition = /* GraphQL */ `
  mutation CreateAppointmentDefinition(
    $input: CreateAppointmentDefinitionInput!
    $condition: ModelAppointmentDefinitionConditionInput
  ) {
    createAppointmentDefinition(input: $input, condition: $condition) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      calendlyAppointmentID
      calendlyAppointment {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      appointmentType
      appointmentSubject
      drPublicId
      drName
      drAvatar
      clinicPublicId
      clinicName
      clinicFormerlyKnownAs
      clinicStatus
      clinicLogoImage
      active
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateAppointmentDefinition = /* GraphQL */ `
  mutation UpdateAppointmentDefinition(
    $input: UpdateAppointmentDefinitionInput!
    $condition: ModelAppointmentDefinitionConditionInput
  ) {
    updateAppointmentDefinition(input: $input, condition: $condition) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      calendlyAppointmentID
      calendlyAppointment {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      appointmentType
      appointmentSubject
      drPublicId
      drName
      drAvatar
      clinicPublicId
      clinicName
      clinicFormerlyKnownAs
      clinicStatus
      clinicLogoImage
      active
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteAppointmentDefinition = /* GraphQL */ `
  mutation DeleteAppointmentDefinition(
    $input: DeleteAppointmentDefinitionInput!
    $condition: ModelAppointmentDefinitionConditionInput
  ) {
    deleteAppointmentDefinition(input: $input, condition: $condition) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      calendlyAppointmentID
      calendlyAppointment {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      appointmentType
      appointmentSubject
      drPublicId
      drName
      drAvatar
      clinicPublicId
      clinicName
      clinicFormerlyKnownAs
      clinicStatus
      clinicLogoImage
      active
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createAppointmentNote = /* GraphQL */ `
  mutation CreateAppointmentNote(
    $input: CreateAppointmentNoteInput!
    $condition: ModelAppointmentNoteConditionInput
  ) {
    createAppointmentNote(input: $input, condition: $condition) {
      id
      note
      appointmentID
      appointment {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        definitionID
        definition {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        booker {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteAppointmentNote = /* GraphQL */ `
  mutation DeleteAppointmentNote(
    $input: DeleteAppointmentNoteInput!
    $condition: ModelAppointmentNoteConditionInput
  ) {
    deleteAppointmentNote(input: $input, condition: $condition) {
      id
      note
      appointmentID
      appointment {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        definitionID
        definition {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        booker {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createAppointmentMedication = /* GraphQL */ `
  mutation CreateAppointmentMedication(
    $input: CreateAppointmentMedicationInput!
    $condition: ModelAppointmentMedicationConditionInput
  ) {
    createAppointmentMedication(input: $input, condition: $condition) {
      id
      mapID
      map {
        id
        mapNumber
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        status
        createdDate
        decisionDate
        expiryDate
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      appointmentID
      appointment {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        definitionID
        definition {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        booker {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      dailyDose
      quantity
      repeats
      repeatInterval
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteAppointmentMedication = /* GraphQL */ `
  mutation DeleteAppointmentMedication(
    $input: DeleteAppointmentMedicationInput!
    $condition: ModelAppointmentMedicationConditionInput
  ) {
    deleteAppointmentMedication(input: $input, condition: $condition) {
      id
      mapID
      map {
        id
        mapNumber
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        status
        createdDate
        decisionDate
        expiryDate
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      appointmentID
      appointment {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        definitionID
        definition {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        booker {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      dailyDose
      quantity
      repeats
      repeatInterval
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createReception = /* GraphQL */ `
  mutation CreateReception(
    $input: CreateReceptionInput!
    $condition: ModelReceptionConditionInput
  ) {
    createReception(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateReception = /* GraphQL */ `
  mutation UpdateReception(
    $input: UpdateReceptionInput!
    $condition: ModelReceptionConditionInput
  ) {
    updateReception(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteReception = /* GraphQL */ `
  mutation DeleteReception(
    $input: DeleteReceptionInput!
    $condition: ModelReceptionConditionInput
  ) {
    deleteReception(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createAccountant = /* GraphQL */ `
  mutation CreateAccountant(
    $input: CreateAccountantInput!
    $condition: ModelAccountantConditionInput
  ) {
    createAccountant(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateAccountant = /* GraphQL */ `
  mutation UpdateAccountant(
    $input: UpdateAccountantInput!
    $condition: ModelAccountantConditionInput
  ) {
    updateAccountant(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteAccountant = /* GraphQL */ `
  mutation DeleteAccountant(
    $input: DeleteAccountantInput!
    $condition: ModelAccountantConditionInput
  ) {
    deleteAccountant(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createClinic = /* GraphQL */ `
  mutation CreateClinic(
    $input: CreateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    createClinic(input: $input, condition: $condition) {
      id
      publicId
      name
      formerlyKnownAs
      status
      receptions {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      accountants {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      doctors {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      admins {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      address
      phone
      logoImage
      location {
        lat
        lon
      }
      openingHours
      bankName
      bankStateBranch
      bankAccountName
      bankAccountNumber
      createdAt
      updatedAt
      sorter
      owner
    }
  }
`;
export const updateClinic = /* GraphQL */ `
  mutation UpdateClinic(
    $input: UpdateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    updateClinic(input: $input, condition: $condition) {
      id
      publicId
      name
      formerlyKnownAs
      status
      receptions {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      accountants {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      doctors {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      admins {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      address
      phone
      logoImage
      location {
        lat
        lon
      }
      openingHours
      bankName
      bankStateBranch
      bankAccountName
      bankAccountNumber
      createdAt
      updatedAt
      sorter
      owner
    }
  }
`;
export const deleteClinic = /* GraphQL */ `
  mutation DeleteClinic(
    $input: DeleteClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    deleteClinic(input: $input, condition: $condition) {
      id
      publicId
      name
      formerlyKnownAs
      status
      receptions {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      accountants {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      doctors {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      admins {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      address
      phone
      logoImage
      location {
        lat
        lon
      }
      openingHours
      bankName
      bankStateBranch
      bankAccountName
      bankAccountNumber
      createdAt
      updatedAt
      sorter
      owner
    }
  }
`;
export const createClinicAdmin = /* GraphQL */ `
  mutation CreateClinicAdmin(
    $input: CreateClinicAdminInput!
    $condition: ModelClinicAdminConditionInput
  ) {
    createClinicAdmin(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      adminID
      admin {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateClinicAdmin = /* GraphQL */ `
  mutation UpdateClinicAdmin(
    $input: UpdateClinicAdminInput!
    $condition: ModelClinicAdminConditionInput
  ) {
    updateClinicAdmin(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      adminID
      admin {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteClinicAdmin = /* GraphQL */ `
  mutation DeleteClinicAdmin(
    $input: DeleteClinicAdminInput!
    $condition: ModelClinicAdminConditionInput
  ) {
    deleteClinicAdmin(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      adminID
      admin {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createClinicReception = /* GraphQL */ `
  mutation CreateClinicReception(
    $input: CreateClinicReceptionInput!
    $condition: ModelClinicReceptionConditionInput
  ) {
    createClinicReception(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      receptionID
      reception {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateClinicReception = /* GraphQL */ `
  mutation UpdateClinicReception(
    $input: UpdateClinicReceptionInput!
    $condition: ModelClinicReceptionConditionInput
  ) {
    updateClinicReception(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      receptionID
      reception {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteClinicReception = /* GraphQL */ `
  mutation DeleteClinicReception(
    $input: DeleteClinicReceptionInput!
    $condition: ModelClinicReceptionConditionInput
  ) {
    deleteClinicReception(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      receptionID
      reception {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createClinicAccountant = /* GraphQL */ `
  mutation CreateClinicAccountant(
    $input: CreateClinicAccountantInput!
    $condition: ModelClinicAccountantConditionInput
  ) {
    createClinicAccountant(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      accountantID
      accountant {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateClinicAccountant = /* GraphQL */ `
  mutation UpdateClinicAccountant(
    $input: UpdateClinicAccountantInput!
    $condition: ModelClinicAccountantConditionInput
  ) {
    updateClinicAccountant(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      accountantID
      accountant {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteClinicAccountant = /* GraphQL */ `
  mutation DeleteClinicAccountant(
    $input: DeleteClinicAccountantInput!
    $condition: ModelClinicAccountantConditionInput
  ) {
    deleteClinicAccountant(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      accountantID
      accountant {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createClinicDoctor = /* GraphQL */ `
  mutation CreateClinicDoctor(
    $input: CreateClinicDoctorInput!
    $condition: ModelClinicDoctorConditionInput
  ) {
    createClinicDoctor(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateClinicDoctor = /* GraphQL */ `
  mutation UpdateClinicDoctor(
    $input: UpdateClinicDoctorInput!
    $condition: ModelClinicDoctorConditionInput
  ) {
    updateClinicDoctor(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteClinicDoctor = /* GraphQL */ `
  mutation DeleteClinicDoctor(
    $input: DeleteClinicDoctorInput!
    $condition: ModelClinicDoctorConditionInput
  ) {
    deleteClinicDoctor(input: $input, condition: $condition) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createCannabisProduct = /* GraphQL */ `
  mutation CreateCannabisProduct(
    $input: CreateCannabisProductInput!
    $condition: ModelCannabisProductConditionInput
  ) {
    createCannabisProduct(input: $input, condition: $condition) {
      id
      idxOrder
      name
      ingredients
      qtyPerDosageUnit
      category
      presentation
      presentationText
      strength
      volume
      sponsor
      archived
      maps {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const updateCannabisProduct = /* GraphQL */ `
  mutation UpdateCannabisProduct(
    $input: UpdateCannabisProductInput!
    $condition: ModelCannabisProductConditionInput
  ) {
    updateCannabisProduct(input: $input, condition: $condition) {
      id
      idxOrder
      name
      ingredients
      qtyPerDosageUnit
      category
      presentation
      presentationText
      strength
      volume
      sponsor
      archived
      maps {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const deleteCannabisProduct = /* GraphQL */ `
  mutation DeleteCannabisProduct(
    $input: DeleteCannabisProductInput!
    $condition: ModelCannabisProductConditionInput
  ) {
    deleteCannabisProduct(input: $input, condition: $condition) {
      id
      idxOrder
      name
      ingredients
      qtyPerDosageUnit
      category
      presentation
      presentationText
      strength
      volume
      sponsor
      archived
      maps {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const createMedicalAuthorisedPrescriber = /* GraphQL */ `
  mutation CreateMedicalAuthorisedPrescriber(
    $input: CreateMedicalAuthorisedPrescriberInput!
    $condition: ModelMedicalAuthorisedPrescriberConditionInput
  ) {
    createMedicalAuthorisedPrescriber(input: $input, condition: $condition) {
      id
      mapNumber
      idxOrder
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      status
      createdDate
      decisionDate
      expiryDate
      archived
      products {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const updateMedicalAuthorisedPrescriber = /* GraphQL */ `
  mutation UpdateMedicalAuthorisedPrescriber(
    $input: UpdateMedicalAuthorisedPrescriberInput!
    $condition: ModelMedicalAuthorisedPrescriberConditionInput
  ) {
    updateMedicalAuthorisedPrescriber(input: $input, condition: $condition) {
      id
      mapNumber
      idxOrder
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      status
      createdDate
      decisionDate
      expiryDate
      archived
      products {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const deleteMedicalAuthorisedPrescriber = /* GraphQL */ `
  mutation DeleteMedicalAuthorisedPrescriber(
    $input: DeleteMedicalAuthorisedPrescriberInput!
    $condition: ModelMedicalAuthorisedPrescriberConditionInput
  ) {
    deleteMedicalAuthorisedPrescriber(input: $input, condition: $condition) {
      id
      mapNumber
      idxOrder
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      status
      createdDate
      decisionDate
      expiryDate
      archived
      products {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const createProductMap = /* GraphQL */ `
  mutation CreateProductMap(
    $input: CreateProductMapInput!
    $condition: ModelProductMapConditionInput
  ) {
    createProductMap(input: $input, condition: $condition) {
      id
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      mapID
      map {
        id
        mapNumber
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        status
        createdDate
        decisionDate
        expiryDate
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateProductMap = /* GraphQL */ `
  mutation UpdateProductMap(
    $input: UpdateProductMapInput!
    $condition: ModelProductMapConditionInput
  ) {
    updateProductMap(input: $input, condition: $condition) {
      id
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      mapID
      map {
        id
        mapNumber
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        status
        createdDate
        decisionDate
        expiryDate
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteProductMap = /* GraphQL */ `
  mutation DeleteProductMap(
    $input: DeleteProductMapInput!
    $condition: ModelProductMapConditionInput
  ) {
    deleteProductMap(input: $input, condition: $condition) {
      id
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      mapID
      map {
        id
        mapNumber
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          createdAt
          owner
          updatedAt
        }
        status
        createdDate
        decisionDate
        expiryDate
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createPatientLink = /* GraphQL */ `
  mutation CreatePatientLink(
    $input: CreatePatientLinkInput!
    $condition: ModelPatientLinkConditionInput
  ) {
    createPatientLink(input: $input, condition: $condition) {
      doctorID
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      clinicID
      createdAt
      updatedAt
    }
  }
`;
export const updatePatientLink = /* GraphQL */ `
  mutation UpdatePatientLink(
    $input: UpdatePatientLinkInput!
    $condition: ModelPatientLinkConditionInput
  ) {
    updatePatientLink(input: $input, condition: $condition) {
      doctorID
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      clinicID
      createdAt
      updatedAt
    }
  }
`;
export const deletePatientLink = /* GraphQL */ `
  mutation DeletePatientLink(
    $input: DeletePatientLinkInput!
    $condition: ModelPatientLinkConditionInput
  ) {
    deletePatientLink(input: $input, condition: $condition) {
      doctorID
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      clinicID
      createdAt
      updatedAt
    }
  }
`;

import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, useIonToast } from '@ionic/react'
import { IonItem, IonLabel, IonRow, IonSpinner, IonText } from '@ionic/react'
import { TabItem, Tabs } from '@aws-amplify/ui-react'
import { add, refresh } from 'ionicons/icons'
import { Doctor } from '../../../API'
import MapNumbersComponent from '../../../components/models/MapNumbersComponent'
import { DoctorService } from '../../../components/services/DoctorService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { UserService } from '../../../components/services/UserService'
import ImageUpload from '../../../components/util/ImageUpload'
import { failure } from '../../../components/util/Toast'
import ClinicAdminContainer from '../../clinicadmin/ClinicAdminContainer'
import AppointmentDefinitionList from '../appointmentDefinitions/AppointmentDefinitionList'
import AppointmentDefinitionModalForm from '../appointmentDefinitions/AppointmentDefinitionModalForm'

interface OwnProps {}
interface DoctorEditProps extends OwnProps {}

const DoctorEdit: FC<DoctorEditProps> = () => {
  const { sub } = useParams<any>()
  const [user, setUser] = useState<any>()
  const [doctor, setDoctor] = useState<Doctor>()
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const [showModalForm, setShowModalForm] = useState(false)
  const [showApptDefs, setShowApptDefs] = useState(false)
  const [present] = useIonToast()

  const addOrEdit = async () => {
    setEditing(true)
    if (!!doctor) {
      const res: TbnResponse = await DoctorService.Instance.addOrEditDoctor(user?.id, doctor)
      if (res.data) {
        setDoctor(res.data)
      } else if (res.errorMessage) failure(res.errorMessage, present)
    }
    setEditing(false)
  }

  const loadUser = async (sub: string) => {
    setLoading(true)
    setUser(undefined)
    setDoctor(undefined)
    const res: TbnResponse | any = await UserService.Instance?.findBySub(sub)
    if (res.data) {
      setUser(res.data)
    } else if (res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setLoading(false)
  }

  const loadDoctor = async (userID: string) => {
    setLoading(true)
    const res: TbnResponse = await DoctorService.Instance.findDoctor(userID)
    if (!!res.data) {
      setDoctor(res.data)
      setShowApptDefs(true)
    } else if (res.errorMessage) {
      failure(res.errorMessage, present)
      setDoctor({ userID } as Doctor)
    }
    setLoading(false)
  }

  const userDisplay = (user: any) => {
    return user?.email
  }

  const isFormInvalid = () => !doctor?.drName || !/^(\w|\.|-)+$/.test(doctor?.drId)

  useEffect(() => {
    if (!!doctor?.avatar) {
      addOrEdit()
    }
  }, [doctor?.avatar]) // eslint-disable-line

  useEffect(() => {
    if (!!sub) {
      loadUser(sub)
    }
  }, [sub]) // eslint-disable-line

  useEffect(() => {
    if (!!user?.id) {
      loadDoctor(user.id)
    }
  }, [user?.id]) // eslint-disable-line

  return (
    <ClinicAdminContainer
      id='doctorEdit'
      title={userDisplay(user)}
      actionButtonsEnd={
        <IonButton onClick={() => loadDoctor(user?.id)}>
          <IonIcon icon={refresh} slot='icon-only' />
        </IonButton>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            {loading && (
              <div className='ion-text-center'>
                <IonSpinner />
              </div>
            )}
            {!loading && !user?.id && (
              <IonText>
                Doctor's user account doesn't exist, Doctor need to login and update her/his given name and surname
                first.
              </IonText>
            )}
          </IonCol>
        </IonRow>
        {!!user && !loading && (
          <>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel className='required'>
                    Public Id{' '}
                    {!/^(\w|\.|-)+$/.test(doctor?.drId || 'y') && (
                      <IonText className='ion-margin-start' color='danger'>
                        Invalid character
                      </IonText>
                    )}
                  </IonLabel>
                  <IonInput
                    value={doctor?.drId}
                    onIonChange={(e: any) => setDoctor({ ...doctor, drId: e.detail.value?.toLowerCase() } as Doctor)}
                    disabled={editing}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel className='required'>Dr Name</IonLabel>
                  <IonText color='tertiary' className='ion-padding-start'>
                    Dr
                  </IonText>
                  <IonInput
                    value={doctor?.drName}
                    onIonChange={(e: any) => setDoctor({ ...doctor, drName: e.detail.value } as Doctor)}
                    disabled={editing}
                  />
                </IonItem>
                <ImageUpload
                  defaultPath='images/doctor/avatar'
                  defaultImage={doctor?.avatar}
                  label='Avatar'
                  avatar={true}
                  onTbnChange={(avatar: string) => setDoctor({ ...doctor, avatar } as Doctor)}
                />
                <IonItem>
                  <IonLabel className='required'>Qualifications</IonLabel>
                  <IonInput
                    value={doctor?.qualifications}
                    onIonChange={(e: any) => setDoctor({ ...doctor, qualifications: e.detail.value } as Doctor)}
                    disabled={editing}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel className='required'>Prescriber number</IonLabel>
                  <IonInput
                    value={doctor?.prescriberNumber}
                    onIonChange={(e: any) => setDoctor({ ...doctor, prescriberNumber: e.detail.value } as Doctor)}
                    disabled={editing}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='ion-text-end'>
                <IonButton onClick={addOrEdit} disabled={editing || isFormInvalid()}>
                  Save
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Tabs spacing='equal'>
                  <TabItem title='Appointment definitions'>
                    <IonButton onClick={() => setShowModalForm(true)} disabled={!doctor?.id}>
                      <IonIcon icon={add} slot='start' />
                      Appointment defs
                    </IonButton>
                    {showApptDefs && doctor?.id && <AppointmentDefinitionList doctorID={doctor?.id} />}
                  </TabItem>
                  <TabItem title='MAP numbers'>{!!doctor && <MapNumbersComponent doctor={doctor} />}</TabItem>
                </Tabs>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonGrid>
      {!!doctor?.id && (
        <AppointmentDefinitionModalForm
          doctor={doctor}
          showModal={showModalForm}
          setShowModal={setShowModalForm}
          onSuccess={() => {
            setShowModalForm(false)
            setShowApptDefs(false)
            setTimeout(() => {
              setShowApptDefs(true)
            }, 1000)
          }}
        />
      )}
    </ClinicAdminContainer>
  )
}

export default DoctorEdit

import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Doctor } from '../../API'
import MapNumbersComponent from '../../components/models/MapNumbersComponent'
import { CurrentDoctorService } from '../../components/services/CurrentDoctorService'
import { isDoctor } from '../../components/util/Auth'
import TbnSpinner from '../../components/util/TbnSpinner'
import MapNumberAddButton from '../admin/medicalAuthorisedPrescriber/MapNumberAddButton'
import DoctorContainer from './DoctorContainer'

interface OwnProps extends RouteComponentProps {}
interface StateProps {}
interface DispatchProps {}
interface MapNumbersProps extends OwnProps, StateProps, DispatchProps {}
const MapNumbers: FC<MapNumbersProps> = () => {
  const [loading, setLoading] = useState(false)
  const { user } = useAuthenticator((context) => [context.user])
  const [doctor, setDoctor] = useState<Doctor>()

  const loadDoctorLoop = async (): Promise<Doctor> => {
    try {
      return await CurrentDoctorService.Instance?.currentDoctor()
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return loadDoctorLoop()
    }
  }

  const loadDoctor = async () => {
    setLoading(true)
    const doctor: Doctor = await loadDoctorLoop()
    setDoctor(doctor)
    setLoading(false)
  }

  useEffect(() => {
    loadDoctor()
  }, []) // eslint-disable-line

  return (
    <DoctorContainer
      id='map-numbers'
      title='MAP numbers'
      actionButtonsEnd={
        <MapNumberAddButton
          onAddSuccess={(data: any) => {
            if (!!data) {
              setLoading(true)
              setTimeout(() => {
                setLoading(false)
              }, 1000)
            }
          }}
        />
      }
    >
      {!loading && isDoctor(user) && !!doctor && <MapNumbersComponent doctor={doctor} />}
      {!loading && isDoctor(user) && !doctor && <IonText color='danger'>Current doctor not found</IonText>}
      {!loading && !isDoctor(user) && <IonText color='danger'>You don't have a doctor role</IonText>}
      {loading && <TbnSpinner />}
    </DoctorContainer>
  )
}

export default MapNumbers
